import * as ACTION from "../constants/triggerActionTypes";

const initialState = {
    loading : false,
    triggerList: [],
    triggerFolderList: [],
    teamUsers: [],
    allAgencyTriggerFolder: [],
    folderList: [],
    data : false,
    response: false,
    triggerRule: '',
    previousActionValue: [],
    allActionItem: [],
    allTriggerItem: [],
    allPipeline: [],
    allVirtualNumber: [],
    allStage: [],
    allCampaign: [],
    allCalendar: [],
    allCollaborator: [],
    allTag: [],
    agency: false,
    profile: false,
    personalize: '',
    triggerActionId: '',
    triggerActionValue:[],
    triggerItemId: '',
    triggerItemValue:[],
    allList:[],
    allForm:[],
    email_templates:[],
    allIntegrations:[],
    required_error: false,
    allSources: [],
    defineFilterTrigger: 0,

    /* for actions reset */
    needToResetActions: false
};

const triggerSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.TRIGGER_SETTING_GET : 
            return {...state, loading: true}
        case ACTION.STORE_TRIGGER_SETTING :
            return {...state,...action.payload}
        case ACTION.SET_TRIGGER_ACTION :
            return {...state,triggerActionId:action.payload.triggerActionId};
        case ACTION.SET_TRIGGER_ACTION_VALUE : 
            let actionValueSet = [...state.triggerActionValue];
            actionValueSet[action.payload.triggerActionValue.index] = action.payload.triggerActionValue.data;
            return {...state,triggerActionValue : actionValueSet}
        case ACTION.SET_REQUIRED_ERROR :
            return {...state, required_error : action.payload.status}
        case ACTION.REMOVE_ACTION_VALUE : 
            let actionValueRemove = [...state.triggerActionValue];
            actionValueRemove.splice(action.payload.rowIndex, 1);
            let previousActionValue = [...state.previousActionValue];
            previousActionValue.splice(action.payload.rowIndex, 1);
            return {...state,
                triggerActionValue : actionValueRemove,
                previousActionValue : previousActionValue};
        case ACTION.SET_TRIGGER_ITEM : 
            return { ...state,triggerItemId : action.payload.triggerItemId};

            case ACTION.SET_TRIGGER_DEFINE_FILTER_VALUE : 
            return { ...state,defineFilterTrigger : action.payload};

        case ACTION.SET_TRIGGER_ITEM_VALUE :
            return { ...state,triggerItemValue : action.payload.triggerItemValue}

        case ACTION.RESET_TRIGGER_ACTIONS :
          return { ...state,needToResetActions : action.payload, triggerActionValue: action.payload ? [] : state.triggerActionValue}
        
        default:
            return state;
    }
}


export default triggerSettingReducer;
