import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


// import components
import Trigger from '../components/trigger/Trigger';
import TriggerSettings from '../components/triggerSettings/TriggerSettings';
import HealthCheck from '../components/HealthCheck';

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path={'/triggers'} component={Trigger}/>
                <Route path={'/trigger/settings/:triggerId'} component={TriggerSettings}/>
                <Route path="/trigger/health-check" component={HealthCheck}/>
            </Switch>
        </Router>
    );
}

export default Routes;