import { Button, FormControl } from '@material-ui/core';
import GlobalModal from '../globals/Modal/GlobalModal';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createShareLink, fetchTriggers, shareTriggerLink } from '../../actions/triggerAction';
import { FileCopy, Share } from '@material-ui/icons';
import { useSimpleValidator } from '../../hooks/useSimpleValidator';


const ModalShareTrigger = (props) => {

    const dispatch = useDispatch();
    const simpleValidator = useSimpleValidator();

    const [link,setLink] = useState('');
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');
    const [open,setOpen] = useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    },[props.open])

    useEffect(() => {
        dispatch(createShareLink({
            triggerId : props.triggerId
        },(response) => {
            setLink(response.data.data.link);
        }));
    },[]);


    const onSubmit = (e) => {

        e.preventDefault();
        if(!simpleValidator.validate()) {
            return;
        }

        dispatch(shareTriggerLink({
            shareableLink : link,
            triggerId : props.triggerId,
            friendsEmail : email,
            message : message
        },(res) => {
            if(res.data.success) {
              window.showNotification('SUCCESS', res.data.message);
              dispatch(fetchTriggers());
              props.onClose();
            }else {
              window.showNotification('ERROR', res.data.message);
            }
        }));
    }

    const closeModal = () => {
        setOpen(false);
        setTimeout(() => {
            props.onClose();
        }, 200);
    }

    return ( 
        <>
            <GlobalModal
                open={open}
                title={"Share Trigger"}
                buttonText={"Share"}
                buttonIcon={<Share/>}
                onSubmit={onSubmit}
                onClose={closeModal}
                >
                
                <h3 className="trigger__modal_title mt10pxSpace">Link</h3>
                <FormControl className="alt trigger_customFormControl copy__wrapper newAddition">
                    <input
                        disabled 
                        className="trigger__modal_textField" 
                        value={link}
                        type="text" 
                        placeholder="Enter Rule Title" />
                    <Button className={"accent--bg--color"} startIcon={<FileCopy/>} onClick={() => navigator.clipboard.writeText(link)}>Copy</Button>
                </FormControl>

                <h3 className="trigger__modal_title mt10pxSpace">Or email your trigger to your friend</h3>
                <FormControl className="alt trigger_customFormControl textarea__wrapper">
                    <textarea placeholder={'Add a message with the link'} onChange={(e) => setMessage(e.target.value)}/>
                </FormControl>  

                <h3 className="trigger__modal_title mt10pxSpace">Email <span className="red-text">*</span></h3>
                <FormControl className="alt trigger_customFormControl">
                    <input
                        className="trigger__modal_textField" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)}
                        type="text" 
                        placeholder="Enter email address here" />
                    {simpleValidator.message('email', email, 'required|email')}
                </FormControl>
            </GlobalModal>
        </>
     );
}

export default ModalShareTrigger; 