import { FormControl } from '@material-ui/core';
import GlobalModal from '../globals/Modal/GlobalModal';
import { MenuItem, Select } from "@material-ui/core";
import { useState } from 'react';
import { connect } from 'react-redux';
import { fetchTriggers, moveRuleToFolder } from '../../actions/triggerAction';
import { OpenWith } from '@material-ui/icons';

const ModalMoveToFolderApp = (props) => {
    let [selectedFolder,setSelectedFolder] = useState(props.folderId);

    const renderList = () => {
        const list = [
            <MenuItem value={null} disabled>
                Select a folder  *
            </MenuItem>
        ];

        props.triggerFolders.forEach(triggerFolder => {
            list.push(<MenuItem className="dropdownhelper-menuitem-class" value={ triggerFolder.id }>{triggerFolder.name}</MenuItem>);
        });

        return list;
    }

    const onSubmit = () => {
        props.moveRuleToFolder({
            folderId : selectedFolder,
            triggerRuleId : props.triggerId
        },(res) => {
          window.showNotification('SUCCESS', res.data.message);
          props.onClose();
          props.fetchTriggers();
        });
    }

    return ( 
        <>
            <GlobalModal {...props} 
                title={"Trigger Move To Folder"}
                buttonText={"Move"}
                buttonIcon={<OpenWith/>}
                onSubmit={onSubmit}
                className="global-small-modal global-xs-modal">
            <h3 className="trigger__modal_title mt10pxSpace">New Rule</h3>
                <FormControl className="alt trigger_customFormControl">
                <Select
                    MenuProps={{
                        anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                        },
                        transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                        },
                        getContentAnchorEl: null
                    }}
                    value={selectedFolder} 
                    onChange={ (e) => setSelectedFolder(e.target.value) }
                    displayEmpty
                    className="trigger__select mxW100m15"
                    inputProps={ { 'aria-label': 'Without label' } }
                >
                    {renderList()}
                </Select>
                </FormControl>      
            </GlobalModal>
        </>
     );
}

const mapDispatchToProps = dispatch => {
    return {
        moveRuleToFolder: (params,callBack) => dispatch(moveRuleToFolder(params,callBack)),
        fetchTriggers: (params) => dispatch(fetchTriggers(params)),
    };
}

const mapStateToProps = state => ({
    triggerFolders: state.triggerReducer.triggerFolders,
});

const ModalMoveToFolder = connect(mapStateToProps, mapDispatchToProps)(ModalMoveToFolderApp);

export default ModalMoveToFolder; 