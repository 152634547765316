import * as ACTION from "../constants/triggerActionTypes";

export const fetchSubUsers = (payload) => ({
    type: ACTION.FETCH_SUB_USERS,
    payload: payload
});

export const fetchCustomFields = (payload,callBack) => ({
    type: ACTION.FETCH_CUSTOM_FIELDS,
    payload: {payload,callBack}
});