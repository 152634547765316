import { FormControl } from '@material-ui/core';
import GlobalModal from '../globals/Modal/GlobalModal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchTriggers, updateRuleTitle } from '../../actions/triggerAction';
import { Save } from '@material-ui/icons';
import { useSimpleValidator } from '../../hooks/useSimpleValidator';

const ModalEditTitle = (props) => {
    const dispatch = useDispatch();
    const simpleValidator = useSimpleValidator();

    const [open,setOpen] = useState(props.open);
    const [title,setTitle] = useState(props.ruleTitle);

    const onSubmit = (e) => {

        e.preventDefault();
        if(!simpleValidator.validate()) {
            return;
        }

        dispatch(updateRuleTitle({
            title : title,
            id : props.triggerId
        },(res) => {
            if(res.data.success) {
              window.showNotification('SUCCESS', res.data.message);
              dispatch(fetchTriggers());
              closeModal();
            }else {
              window.showNotification('ERROR', res.data.message);
            }
        }));
    }

    //To close with transitions.
    const closeModal = () => {
        setOpen(false);
        setTimeout(() => {
            props.onClose();
        }, 200);
    }

    return ( 
        <GlobalModal
            open={open}
            title={"Edit Rule Title"}
            buttonText={"Save"}
            buttonIcon={<Save/>}
            onSubmit={onSubmit}
            onClose={closeModal}
            className="global-small-modal global-xs-modal">
        <h3 className="trigger__modal_title mt10pxSpace">Title <span className="red-text">*</span></h3>
            <FormControl className="alt trigger_customFormControl">
                <input 
                    className="trigger__modal_textField" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)}
                    type="text" 
                    placeholder="Enter Rule Title" />
                {simpleValidator.message('title', title, 'required')}
            </FormControl>      
        </GlobalModal>
     );
}

export default ModalEditTitle; 