import { useState } from "react";
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../globals/CustomAccordion";
import EachTrigger from "./EachTrigger";
import If from 'if-else-react';
import ModalEditFolder from "./ModalEditFolder";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { deleteFolder, fetchTriggers } from "../../actions/triggerAction";
import { connect } from 'react-redux';

const EachFolderApp = (props) => {
	const [isOpenEditFolderModal,setIsOpenEditFolderModal] = useState(false);
	const folderLength = props.folder.trigger_folder_items.length;

	const handleDelete = (e) => {
		e.stopPropagation();
		confirmAlert({
            title: 'Confirm to delete?',
            message: 'Are you sure you want to delete this Folder? Trigger items also will be deleted',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        props.deleteFolder({
                            folder_id : props.folder.id
                        },(res) => {
							if(res.data.success) {
                window.showNotification('success', res.data.message);
								props.fetchTriggers();
							} else {
                window.showNotification('ERROR', res.data.message);
							}
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
	}

    return (
        <>
			<CustomAccordion className={"collapsible defaultToggle trigger__deluxe"}>
				<CustomAccordionHeader className="collapsible-header trigger__header flex content-between items-center trigger-folder">
					
					<div className="trigger__left flex items-center trigger__subtitle">
					<svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-6yvdg3 px-10" style={{"margin-right":"10px"}} focusable="false" viewBox="0 0 24 24" aria-hidden="true" data-testid="FolderOpenTwoToneIcon" tabindex="-1" title="FolderOpenTwoTone"><path d="M4 8h16v10H4z" opacity=".3"></path><path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10z"></path></svg>
						{props.folder.name} ({folderLength})
						<svg className="arrow__icon" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M12.4999 23.7499C12.2078 23.7505 11.9248 23.6488 11.6999 23.4624C11.5733 23.3575 11.4687 23.2286 11.392 23.0832C11.3153 22.9377 11.2681 22.7786 11.253 22.6149C11.2379 22.4512 11.2552 22.2861 11.304 22.1291C11.3528 21.9721 11.4321 21.8262 11.5374 21.6999L17.1374 14.9999L11.7374 8.28743C11.6335 8.15957 11.556 8.01245 11.5092 7.85452C11.4624 7.6966 11.4473 7.53098 11.4647 7.3672C11.4822 7.20341 11.5318 7.04468 11.6107 6.90014C11.6897 6.75559 11.7965 6.62808 11.9249 6.52493C12.0542 6.41113 12.2057 6.3253 12.3697 6.27281C12.5338 6.22032 12.707 6.20232 12.8783 6.21992C13.0497 6.23753 13.2156 6.29037 13.3656 6.37512C13.5155 6.45987 13.6464 6.57471 13.7499 6.71243L19.7874 14.2124C19.9712 14.4361 20.0717 14.7166 20.0717 15.0062C20.0717 15.2957 19.9712 15.5763 19.7874 15.7999L13.5374 23.2999C13.412 23.4512 13.2527 23.5708 13.0724 23.649C12.8922 23.7271 12.696 23.7617 12.4999 23.7499Z" fill="#133159"/> </svg> 

					</div>
					<div className="trigger__right">
						<button className="trigger__btn trigger__edit trigger__grayBtn" onClick={(e) => {e.stopPropagation();setIsOpenEditFolderModal(true)}}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M9.08573 2.91356L13.0862 6.91425L4.39929 15.6016L0.832505 15.9953C0.355018 16.0482 -0.0484094 15.6444 0.00471426 15.1669L0.401579 11.5975L9.08573 2.91356ZM15.5606 2.31793L13.6822 0.43946C13.0962 -0.146487 12.146 -0.146487 11.56 0.43946L9.7929 2.20668L13.7934 6.20737L15.5606 4.44015C16.1465 3.85389 16.1465 2.90388 15.5606 2.31793Z" fill="#133159"/> </svg>Edit</button>
						<button className="trigger__btn trigger__delete trigger__grayBtn" onClick={handleDelete}><svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.7497 1.99983H8.99979V1.49984C8.99979 0.671425 8.32824 -0.00012207 7.49983 -0.00012207H5.49987C4.67145 -0.00012207 3.99991 0.671425 3.99991 1.49984V1.99983H1.24997C0.559643 1.99983 0 2.55947 0 3.2498V4.24978C0 4.52593 0.223839 4.74977 0.499988 4.74977H12.4997C12.7759 4.74977 12.9997 4.52593 12.9997 4.24978V3.2498C12.9997 2.55947 12.4401 1.99983 11.7497 1.99983ZM4.99988 1.49984C4.99988 1.22422 5.22425 0.999855 5.49987 0.999855H7.49983C7.77544 0.999855 7.99981 1.22422 7.99981 1.49984V1.99983H4.99988V1.49984Z" fill="#FF264A"/> <path d="M0.948964 5.75043C0.859748 5.75043 0.788656 5.82499 0.792906 5.91411L1.2054 14.5715C1.24352 15.3727 1.90163 16.0002 2.70349 16.0002H10.2964C11.0983 16.0002 11.7564 15.3727 11.7945 14.5715L12.207 5.91411C12.2113 5.82499 12.1402 5.75043 12.051 5.75043H0.948964ZM8.49991 7.0004C8.49991 6.72415 8.72366 6.50041 8.9999 6.50041C9.27615 6.50041 9.49989 6.72415 9.49989 7.0004V13.5002C9.49989 13.7765 9.27615 14.0002 8.9999 14.0002C8.72366 14.0002 8.49991 13.7765 8.49991 13.5002V7.0004ZM5.99997 7.0004C5.99997 6.72415 6.22372 6.50041 6.49996 6.50041C6.7762 6.50041 6.99995 6.72415 6.99995 7.0004V13.5002C6.99995 13.7765 6.7762 14.0002 6.49996 14.0002C6.22372 14.0002 5.99997 13.7765 5.99997 13.5002V7.0004ZM3.50003 7.0004C3.50003 6.72415 3.72378 6.50041 4.00002 6.50041C4.27626 6.50041 4.50001 6.72415 4.50001 7.0004V13.5002C4.50001 13.7765 4.27626 14.0002 4.00002 14.0002C3.72378 14.0002 3.50003 13.7765 3.50003 13.5002V7.0004Z" fill="#FF264A"/> </svg> Delete </button>
					</div>
				</CustomAccordionHeader>
				<CustomAccordionBody className={`collapsible-body ${folderLength == 0 ? "noItems" : ""}`}>
					<div className="listItems">
						{
							folderLength == 0 ? <p className="emptyRules">No Rule Found</p> : 
							props.folder.trigger_folder_items.map(trigger => {
								return (<EachTrigger trigger={trigger.trigger}/>)
							})
						}
						
					</div>
				</CustomAccordionBody>
			</CustomAccordion>
			<If condition={isOpenEditFolderModal !== false}>
				<ModalEditFolder
					open={isOpenEditFolderModal}
					folderName={props.folder.name}
					folderId={props.folder.id}
					onClose={() => setIsOpenEditFolderModal(false)}/>
			</If>
        </> );
}

const mapDispatchToProps = dispatch => {
    return {
        deleteFolder: (params,callBack) => dispatch(deleteFolder(params,callBack)),
        fetchTriggers: (params) => dispatch(fetchTriggers(params)),
    };
}

const mapStateToProps = state => ({});

const EachFolder = connect(mapStateToProps, mapDispatchToProps)(EachFolderApp);

export default EachFolder; 