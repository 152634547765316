import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import  TriggerItem from './TriggerItem';
import { settingGet } from '../../actions/triggerAction';
import ActionItem from './ActionItem';
import TriggerSettingsHeader from './TriggerSettingsHeader';
import './trigger-settings.css';
import Utils from '../../helpers/Utils';
import Loading from '../common/Loading';

const TriggerSettings = (props) => {

    const dispatch = useDispatch();
    const { loading, data, response } = useSelector(state => state.triggerSettingReducer);

    useEffect(() => {

        document.title = `Trigger | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('trigger');
        }

        dispatch(settingGet({
            triggerRuleId : props.match.params.triggerId
        }));
    },[])

    return (
        <div className="trigger__settings">
            {
                response && 
                <div className="row">
                    <div className="col">
                        { response.status === 'error' ?
                            <div className="alert alert-danger" role="alert">
                                <div className="alert-text">
                                    {response.message}
                                </div>
                            </div> :
                            <div className="alert alert-success" role="alert">
                                <div className="alert-text">
                                    {response.message}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }

            {
                loading ? 
                <div className="col-12 loader-min-height d-flex justify-content-center">
                    <div className="align-self-center">
                        <div className="text-center">
                            <Loading/>
                        </div>
                    </div>
                </div> : 
                <>
                    <TriggerSettingsHeader/>

                    <div className="trigger__custom_row">
                        <div className="column1 trigger__left_column">
                            {
                                data && <TriggerItem/>
                            }
                        </div>
                        <div className="column1 trigger__right_column">
                            {
                                data && <ActionItem/>
                            }
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default TriggerSettings;