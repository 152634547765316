import { FormControl } from '@material-ui/core';
import { iconList } from '../globals/IconList';
import GlobalModal from '../globals/Modal/GlobalModal';
import {  MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from 'react';
import { fetchSubUsers } from '../../actions/commonAction';
import { useDispatch, useSelector } from 'react-redux';
import { cloneTriggerAndAssignUser } from '../../actions/triggerAction';
import { DropdownMenuProps } from '../../helpers/Settings';

const ModalCloneTriggerAssignUser = (props) => {
    const dispatch = useDispatch();
    const subUsers = useSelector(state => state.commonReducer.subUsers);

    const [selectedUser,setSelectedUser] = useState("");
    const [open,setOpen] = useState(props.open);

    useEffect(() => {
        setOpen(props.open);
    },[props.open])

    useEffect(() => {
        dispatch(fetchSubUsers());
    },[]);

    const renderList = () => {
        const list = [
            <MenuItem value="" disabled>
                Select a user  *
            </MenuItem>
        ];

        subUsers.forEach(user => {
            list.push(<MenuItem className="dropdownhelper-menuitem-class" value={ user.id }>{user.full_name}</MenuItem>);
        });

        return list;
    }

    const onSubmit = () => {
        dispatch(cloneTriggerAndAssignUser({
            assignedUserId : selectedUser,
            triggerRuleId : props.triggerId
        },(res) => {
          window.showNotification('SUCCESS', res.data.message);
          closeModal();
        }));
    }

    const closeModal = () => {
        setOpen(false);
        setTimeout(() => {
            props.onClose();
        }, 200);
    }

    return ( 
        <GlobalModal
            open={open}
            title={"Clone Trigger To User"}
            buttonText={"Clone Trigger"}
            buttonIcon={iconList.duplicateIconSize20}
            onSubmit={onSubmit}
            onClose={closeModal}>
            <h3 className="trigger__modal_title mt10pxSpace">New Rule</h3>
            <FormControl className="alt trigger_customFormControl">
            <Select
                MenuProps={DropdownMenuProps}
                value={selectedUser} 
                onChange={ (e) => setSelectedUser(e.target.value) }
                displayEmpty
                className="trigger__select"
                inputProps={ { 'aria-label': 'Without label' } }
            >
                {renderList()}
            </Select>
            </FormControl>      
        </GlobalModal>
     );
}

export default ModalCloneTriggerAssignUser; 