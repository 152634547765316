import triggerMiddleware from './triggerMiddleware';
import { all } from "redux-saga/effects";
import commonMiddleware from './commonMiddleware';
import triggerSettingMiddleware from './triggerSettingMiddleware';

export default function* rootMiddleware() {
    yield all(
        [
            triggerMiddleware(),triggerSettingMiddleware(),commonMiddleware()
        ]
    )
}
