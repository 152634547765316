import { Button } from "@material-ui/core";
import React, { useState } from "react"; 
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import GlobalDropdown, { GlobalDropDownButton, GlobalDropDownItem } from "../globals/Dropdown/GlobalDropdown";
import {connect} from "react-redux";
import { iconList } from "../globals/IconList";
import CoreConstants from "../../constants/CoreConstants";
import * as TIGGER_ACTION from "../../actions/triggerAction";
import { useDispatch } from 'react-redux';
import ModalCloneTriggerAssignUser from "./ModalCloneTriggerAssignUser";
import If from 'if-else-react';
import ModalMoveToFolder from "./ModalMoveToFolder";
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import ModalEditTitle from "./ModalEditTitle";
import ModalShareTrigger from "./ModalShareTrigger";
import CustomSwitch from "../common/Switch";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import NewConfirmAlert from "../common/new-alert/NewConfirmAlert";

const EachTriggerApp = (props) => {

    const dispatch = useDispatch();
    const [isOpenCloneTriggerAssignUser,setIsOpenCloneTriggerAssignUser] = useState(false);
    const [isOpenMoveToFolder,setIsOpenMoveToFolder] = useState(false);
    const [isOpenEditTitleModal,setIsOpenEditTitleModal] = useState(false);
    const [isOpenShareModal,setIsOpenShareModal] = useState(false);
    const [triggerStatus,setTriggerStatus] = useState(props.trigger.status == CoreConstants.TRIGGER_STATUS.STATUS_START ? true : false);

    const onStatusChange = (status) => {

        try {
            setTriggerStatus(status);
            let changeTo = (status == true) ? CoreConstants.TRIGGER_STATUS.STATUS_START : CoreConstants.TRIGGER_STATUS.STATUS_PAUSE;
            dispatch(TIGGER_ACTION.changeStatus({ status : changeTo , triggerRuleId : props.trigger.id},(res) => {
                if(res.data.success) {
                    window.showNotification('SUCCESS', res.data.message)
                }else {
                    setTriggerStatus(!status); //api error set it as it was
                    window.showNotification('ERROR', res.data.message)
                }
            }));
        } catch (error) {
            console.log(error)
        }
        
        
    }

    const gotoSettings = () => {
        window.location.href = `/trigger/settings/${props.trigger.id}`;
    }


    const duplicateTrigger = () => {
        dispatch(TIGGER_ACTION.duplicateTrigger({
            triggerRuleId : props.trigger.id
        },(res) => {
            if(res.data.success) {
                window.showNotification('SUCCESS', res.data.message)
                dispatch(TIGGER_ACTION.fetchTriggers());
            }else {
                window.showNotification('ERROR', res.data.message)
            }
            
        }));
    }

    const removeFromFolder = () => {

        confirmAlert({
            title: 'Confirm to remove from folder?',
            message: 'Are you sure to remove this rule from folder?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        dispatch(TIGGER_ACTION.removeFromFolder({
                            triggerRuleId : props.trigger.id
                        },(res) => {
                            if(res.data.success) {
                                window.showNotification('SUCCESS', res.data.message)
                                dispatch(TIGGER_ACTION.fetchTriggers());
                            }else {
                                window.showNotification('ERROR', res.data.message)
                            }
                        }));
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        });
    }

    const deleteTrigger = () => {

        NewConfirmAlert({
            onSubmit : async() => {
                dispatch(TIGGER_ACTION.deleteTrigger({
                    trigger_id : props.trigger.id
                },(res) => {
                    if(res.data.success) {
                        window.showNotification('SUCCESS', res.data.message)
                        dispatch(TIGGER_ACTION.fetchTriggers());
                    }else {
                        window.showNotification('ERROR', res.data.message)
                    }
                }));
            },
              title: `Are you sure you want to delete this trigger?`,
              description: '',
              cancelText: 'No',
              submitText: 'Yes'
        });

    }
    
    return (
        <>
            <div className="listItem bg-white">
                <div className="left__list">
                    
                    <BootstrapTooltip arrow title={props.trigger.title} placement="top-start">
                        <span className="cursor-pointer" style={{width: '100%'}} onClick={gotoSettings}>{props.trigger.title}</span>
                    </BootstrapTooltip>
                    
                    <div className="status">
                        <CustomSwitch 
                            status={triggerStatus} 
                            brandColor={"#f94f72"} 
                            onChange={onStatusChange}
                        />
                    </div>
                </div>

                <div className="right__list">
                    <GlobalDropdown>
                        <GlobalDropDownButton>
                            <Button
                                className="list__settings_btn"
                                variant="contained"
                                color="secondary"
                                startIcon={<SettingsOutlinedIcon />}
                                endIcon={<ExpandMoreOutlinedIcon />}
                                >
                                    Settings
                            </Button>
                        </GlobalDropDownButton>
                        <GlobalDropDownItem onClick={() => gotoSettings()} onClickHide={true} title='Edit' icon={iconList.gearIcon}/>
                        <GlobalDropDownItem onClick={() => duplicateTrigger()} title='Duplicate' onClickHide={true} icon={iconList.duplicateIcon}/>
                        {/* <GlobalDropDownItem onClick={() => setIsOpenCloneTriggerAssignUser(true)} onClickHide={true} title='Clone Trigger & Assign User' icon={iconList.duplicateIcon}/> */}
                        <GlobalDropDownItem onClick={() => setIsOpenMoveToFolder(true)} onClickHide={true}  title={`Move To ${props.trigger.user_trigger_folder_id !== null ? 'Another' : ''} Folder`} icon={iconList.folderIcon}/>
                        {
                            props.trigger.user_trigger_folder_id !== null && 
                            <GlobalDropDownItem title='Remove From Folder' onClick={() => removeFromFolder()} onClickHide={true} icon={iconList.closeIcon}/>
                        }
                        <GlobalDropDownItem onClick={() => setIsOpenEditTitleModal(true)} onClickHide={true} title='Edit Title' icon={iconList.pencilIcon}/>
                        <GlobalDropDownItem title='Share' onClick={() => setIsOpenShareModal(true)} onClickHide={true} icon={iconList.shareIcon}/>
                        <GlobalDropDownItem className={'red__item'} title='Delete' onClick={() => deleteTrigger()} onClickHide={true} icon={iconList.trashIcon}/>
                    </GlobalDropdown>
                </div>
            </div>

            <If condition={isOpenCloneTriggerAssignUser !== false}>
                <ModalCloneTriggerAssignUser
                    triggerId={props.trigger.id}
                    open={isOpenCloneTriggerAssignUser}
                    onClose={() => setIsOpenCloneTriggerAssignUser(false)}/>
            </If>

            <If condition={isOpenMoveToFolder}>
                <ModalMoveToFolder
                    triggerId={props.trigger.id}
                    folderId={props.trigger.user_trigger_folder_id}
                    open={isOpenMoveToFolder}
                    onClose={() => setIsOpenMoveToFolder(false)}/>
            </If>

            <If condition={isOpenEditTitleModal !== false}>
                <ModalEditTitle
                    triggerId={props.trigger.id}
                    ruleTitle={props.trigger.title}
                    open={isOpenEditTitleModal}
                    onClose={() => setIsOpenEditTitleModal(false)}/>
            </If>
            
            <If condition={isOpenShareModal !== false}>
                <ModalShareTrigger
                    triggerId={props.trigger.id}
                    ruleTitle={props.trigger.title}
                    open={isOpenShareModal}
                    onClose={() => setIsOpenShareModal(false)}/>
            </If>
            
        </>
    );
}



const mapStateToProps = state => ({
    triggerItemValue: state.triggerSettingReducer.triggerItemValue,
});

const EachTrigger = connect(mapStateToProps)(EachTriggerApp);

export default EachTrigger; 