import { Skeleton } from '@material-ui/lab';
import React from 'react'

export const RenderSkeleton=()=> {

    const loadingSkeleton = () => {
        let skeletons = [];
        for (let i = 0; i < 4; i++) {
            skeletons.push(
                <div key={i} className="skeleton_each_trigger">
                    <Skeleton
                        className="skeleton_trigger_circle_icon"
                        variant="circle"
                        width={50}
                        height={50}
                    />
                    <div className="skeleton_trigger_details">
                        <Skeleton variant="rect" width={"100%"} height={50} />
                    </div>
                   
                </div>
            );
        }
        return skeletons;
    };
  return (
    <div>
        {loadingSkeleton()}
    </div>
  )
}
