import React from 'react';
import axis from "axis.js";
import Creatable from 'react-select/creatable';
import { ANNIVERSARY_REMAINDER, APPOINTMENT, BIRTHDAY_REMAINDER, CONTACT_REPLIED, CONTACT_TAG, STALE_OPPORTUNITY, TASK_REMAINDER, TRIGGER_TYPES } from '../../constants/CoreConstants';
import { iconList } from '../globals/IconList';
import { Button } from '@material-ui/core';


let months = [
    { 1 : 'January' },
    { 2 : 'February' },
    { 3 : 'March' },
    { 4 : 'April' },
    { 5 : 'May' },
    { 6 : 'June' },
    { 7 : 'July' },
    { 8 : 'August' },
    { 9 : 'September' },
    { 10 : 'October' },
    { 11 : 'November' },
    { 12 : 'December' },
];

const EachTrigger = (props) => {

    const timeIntervalOptions = (interval) => {
        let option=[];
        for (let ap=1;ap<=2;ap++){
            for (let i=0;i<12;i++){
                for(let j=0;j<60/interval;j++){
                    let hv=i, h=i;
                    if(i === 0) h=12
                    if(ap!==1) hv= hv+12;
                    option.push(<option value={('0' + hv).slice(-2)+':'+('0' + interval*j).slice(-2)} data-content="<i class='fa fa-address-book-o' aria-hidden='true'></i>Option1">{('0' + h).slice(-2)+':'+('0' + interval*j).slice(-2)+' '+(ap===1?'AM':'PM')}</option>)
                }
            }
        }
        return option;
    }

    const getDefaultOptionForTag = () => {
        let allOptions = [];
        if (props.allTag.length > 0) {
            allOptions = props.allTag.map((data, index) => {
                return {
                    value: data.id,
                    label: data.name
                }
            });
        }
        return allOptions;
    };

    const actionMultiTagAdd = (selectedTag, action) => {
        props.setSelectedTagOption(props.RowIndex, selectedTag);
    };

    const getOptions = () => {
        let options = '';
        if(!axis.isUndefined(props.remainingValuesToTrigger)){
            options = props.remainingValuesToTrigger.map((data,index) => {
                return(
                    <option key={Object.keys(data)} value={Object.keys(data)}>{data[Object.keys(data)]}</option>
                )
            });
        }

        return options;
    }

    const getCampaignOptions = () => {
        let campaignOptions = '';
        if(!axis.isUndefined(props.allCampaign)){
            campaignOptions = props.allCampaign.map(function(data,index){
                return (
                    <option key={index} value={data.id}>{data.title}</option>
                )
            });
        }

        return campaignOptions;
    }

    const getCalendarOptions = () => {
        let calendarOptions = '';
        if(!axis.isUndefined(props.allCalendar)){
            calendarOptions = props.allCalendar.map(function(data,index){
                return (
                    <option key={index} value={data.id}>{data.title}</option>
                )
            });
        }

        return calendarOptions;
    }

    const getTagOptions = () => {
        let tagOptions = '';
        if(!axis.isUndefined(props.allTag)){
            tagOptions = props.allTag.map(function(data,index){
                return (
                    <option key={index} value={data.id}>{data.name}</option>
                )
            });
        }

        return tagOptions;
    }

    const getMonthOptions = () => {
        return months.map(function(data,index){
            return(
                <option key={index} value={index+1} >{data[index+1]}</option>
            )
        });
    }

    const getDayOptions = () => {
        let list = [];
        for (let i = 1; i <= 31; i++) {
            list.push(i);
        }
        return list.map(function(data,index){
            return(
                <option key={index} value={data} >{data}</option>
            )
        });
    }

    return (
        <div className="campaign__filter_row">

            <div className={'campaign__filter_col'}>
                <div className="form-group m-form__group ">
                    <div className="form-group m-form__group">
                        <select name={props.triggerType+"_interval_" + props.RowIndex} value={props.selectedValue ? props.selectedValue : ''} className={'form-control d-block'} disabled={((props.currentTotalRow-1) == props.RowIndex) ? null:true} onChange={(e) => props.onValueChange(props.RowIndex, e.target.value, props.triggerType)}>
                            <option>Select an event</option>
                            {getOptions()}
                        </select>
                    </div>
                </div>
            </div>

            {(props.triggerType == TRIGGER_TYPES.CONTACT_REPLIED && props.selectedValue) &&
                <div className={'campaign__filter_col'}>
                {props.selectedValue == CONTACT_REPLIED.IN_CAMPAIGN &&
                    <div className="">
                        <select className="form-control d-block"
                                name="unit"
                                value={props.triggerSubValue ? props.triggerSubValue : ''}
                                onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                            <option>Select a campaign</option>
                            {getCampaignOptions()}
                        </select>
                    </div>
                }
                {props.selectedValue == CONTACT_REPLIED.REPLY_CHANNEL &&
                    <div className="">
                        <select className="form-control d-block"
                                name="unit"
                                value={props.triggerSubValue ? props.triggerSubValue : ''}
                                onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                            <option>Select reply channel</option>
                            <option value="0">SMS</option>
                            <option value="1">Email</option>
                            <option value="2">Call</option>
                            <option value="3">MMS</option>
                        </select>
                    </div>
                }
                {props.selectedValue == CONTACT_REPLIED.REPLY_KEYWORD &&
                    <div className="">
                        <input className="form-control m-input m-input--square numberInputField"
                                name="replay_keyword"
                                value={props.triggerSubValue ? props.triggerSubValue : ''}
                                onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}/>
                    </div>
                }
                {props.selectedValue == CONTACT_REPLIED.CONTAIN_PHRASE &&
                    <div className="">
                        <input className="form-control m-input m-input--square numberInputField"
                                name="contain_phrase"
                                value={props.triggerSubValue ? props.triggerSubValue : ''}
                                onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}/>
                    </div>
                }
                {props.selectedValue == CONTACT_REPLIED.EXACT_MATCH_PHRASE &&
                    <div className="">
                        <input className="form-control m-input m-input--square numberInputField"
                                name="exact_match_phrase"
                                value={props.triggerSubValue ? props.triggerSubValue : ''}
                                onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}/>
                    </div>
                }
            </div>
            }
            {(props.triggerType == TRIGGER_TYPES.ADDED_TAG && props.selectedValue) &&
                <div className={'campaign__filter_col'}>
                    {(props.selectedValue == CONTACT_TAG.TAG_ADDED || props.selectedValue == CONTACT_TAG.TAG_REMOVED) &&
                        <div className="">
                            <select className="form-control d-block"
                                    name="tag_added"
                                    value={props.triggerSubValue ? props.triggerSubValue : ''}
                                    onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                                <option>Select a tag</option>
                                {getTagOptions()}
                            </select>
                        </div>
                    }
                </div>
            }

            {(props.triggerType == TRIGGER_TYPES.TASK_REMAINDER && props.selectedValue) &&
                <div className={'campaign__filter_col'}>
                    {(props.selectedValue == TASK_REMAINDER.BEFORE_NO_OF_DAYS || props.selectedValue == TASK_REMAINDER.AFTER_NO_OF_DAYS) &&
                        <div className="">
                            <input className="form-control m-input m-input--square numberInputField"
                                name="task_remainder"
                                type={'number'}
                                value={props.triggerSubValue ? props.triggerSubValue : ''}
                                onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}/>
                        </div>
                    }
                </div>
            }
            {(props.triggerType == TRIGGER_TYPES.APPOINTMENT && props.selectedValue) &&
                <div className={'campaign__filter_col'}>
                    {(props.selectedValue == APPOINTMENT.IN_CALENDAR) &&
                        <div className="">
                            <select className="form-control d-block"
                                    name="calendar_option"
                                    value={props.triggerSubValue ? props.triggerSubValue : ''}
                                    onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                                <option>Select a calendar</option>
                                {getCalendarOptions()}
                            </select>
                        </div>
                    }
                    {(props.selectedValue == APPOINTMENT.APPOINTMENT_STATUS_IS) &&
                        <div className="">
                            <select className="form-control d-block"
                                    name="tag_added"
                                    value={(props.triggerSubValue || props.triggerSubValue === 0) ? props.triggerSubValue : ''}
                                    onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                                <option>Select a status</option>
                                <option value="0">Pending</option>
                                <option value="1">Upcoming</option>
                            </select>
                        </div>
                    }
                </div>
            }
            {(props.triggerType == TRIGGER_TYPES.ANNIVERSARY_REMAINDER && props.selectedValue) &&
                <div className={'campaign__filter_col'}>
                    {(props.selectedValue == ANNIVERSARY_REMAINDER.BEFORE_NO_OF_DAYS || props.selectedValue == ANNIVERSARY_REMAINDER.AFTER_NO_OF_DAYS) &&
                        <div className="">
                            <input className="form-control m-input m-input--square numberInputField"
                                name="task_remainder"
                                type={'number'}
                                value={props.triggerSubValue ? props.triggerSubValue : ''}
                                onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}/>
                        </div>
                    }
                    {(props.selectedValue == ANNIVERSARY_REMAINDER.MONTH_IS) &&
                        <div className="">
                            <select className="form-control d-block"
                                    name="month"
                                    value={props.triggerSubValue ? props.triggerSubValue : ''}
                                    onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                                <option>Select a month</option>
                                {getMonthOptions()}
                            </select>
                        </div>
                    }
                    {(props.selectedValue == ANNIVERSARY_REMAINDER.DAY_IS) &&
                        <div className="">
                            <select className="form-control d-block"
                                    name="tag_added"
                                    value={props.triggerSubValue ? props.triggerSubValue : ''}
                                    onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                                <option>Select a day</option>
                                {getDayOptions()}
                            </select>
                        </div>
                    }
                    {(props.selectedValue == ANNIVERSARY_REMAINDER.CURRENT_DAY) &&
                    <div className="">
                        <select name="" className={'form-control d-block'}
                                value={props.triggerSubValue ? props.triggerSubValue : ''}
                                onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                                <option value={null}>Select time</option>
                                {timeIntervalOptions(15)}
                        </select>
                    </div>
                    }
                    {(props.selectedValue == ANNIVERSARY_REMAINDER.HAS_TAG) &&
                    <div className="">
                        <div className="form-group m-form__group">
                            <Creatable
                                isClearable={false}
                                options={getDefaultOptionForTag()}
                                value={props.triggerSubValue}
                                isMulti={true}
                                onChange={actionMultiTagAdd} />
                        </div>
                    </div>
                    }
                    {(props.selectedValue == ANNIVERSARY_REMAINDER.DOES_NOT_HAS_TAG) &&
                    <div className="">
                        <div className="form-group m-form__group">
                            <Creatable
                                isClearable={false}
                                options={getDefaultOptionForTag()}
                                value={props.triggerSubValue}
                                isMulti={true}
                                onChange={actionMultiTagAdd} />
                        </div>
                    </div>
                    }
                </div>
            }

            {(props.triggerType == TRIGGER_TYPES.BIRTHDAY_REMAINDER && props.selectedValue) &&
                <div className={'campaign__filter_col'}>
                    {(props.selectedValue == BIRTHDAY_REMAINDER.BEFORE_NO_OF_DAYS || props.selectedValue == BIRTHDAY_REMAINDER.AFTER_NO_OF_DAYS) &&
                        <div className="">
                            <input className="form-control m-input m-input--square numberInputField"
                                name="task_remainder"
                                type={'number'}
                                value={props.triggerSubValue ? props.triggerSubValue : ''}
                                onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}/>
                        </div>
                    }
                    {(props.selectedValue == BIRTHDAY_REMAINDER.MONTH_IS) &&
                        <div className="">
                            <select className="form-control d-block"
                                    name="month"
                                    value={props.triggerSubValue ? props.triggerSubValue : ''}
                                    onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                                <option>Select a month</option>
                                {getMonthOptions()}
                            </select>
                        </div>
                    }
                    {(props.selectedValue == BIRTHDAY_REMAINDER.DAY_IS) &&
                        <div className="">
                            <select className="form-control d-block"
                                    name="tag_added"
                                    value={props.triggerSubValue ? props.triggerSubValue : ''}
                                    onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                                <option>Select a day</option>
                                {getDayOptions()}
                            </select>
                        </div>
                    }
                    {(props.selectedValue == BIRTHDAY_REMAINDER.CURRENT_DAY) &&
                    <div className="">
                        <select name="" className={'form-control d-block'}
                                value={props.triggerSubValue ? props.triggerSubValue : ''}
                                onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                            {timeIntervalOptions(15)}
                        </select>
                    </div>
                    }
                    {(props.selectedValue == BIRTHDAY_REMAINDER.HAS_TAG) &&
                    <div className="">
                        <div className="form-group m-form__group">
                            <Creatable
                                isClearable={false}
                                options={getDefaultOptionForTag()}
                                value={props.triggerSubValue}
                                isMulti={true}
                                onChange={actionMultiTagAdd} />
                        </div>
                    </div>
                    }
                    {(props.selectedValue == BIRTHDAY_REMAINDER.DOES_NOT_HAS_TAG) &&
                    <div className="">
                        <div className="form-group m-form__group">
                            <Creatable
                                isClearable={false}
                                options={getDefaultOptionForTag()}
                                value={props.triggerSubValue}
                                isMulti={true}
                                onChange={actionMultiTagAdd} />
                        </div>
                    </div>
                    }
                </div>
            }

            {(props.triggerType == TRIGGER_TYPES.STALE_OPPORTUNITY && props.selectedValue) &&
                <div className={'campaign__filter_col'}>
                    {(props.selectedValue == STALE_OPPORTUNITY.DURATION_IN_DAYS) &&
                        <div className="">
                            <input className="form-control m-input m-input--square numberInputField"
                                   name="duration_in_days"
                                   type={'number'}
                                   value={props.triggerSubValue ? props.triggerSubValue : ''}
                                   onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}/>
                        </div>
                    }
                    {(props.selectedValue == STALE_OPPORTUNITY.IN_ACTIVE_CAMPAIGN) &&
                        <div className="">
                            <select className="form-control d-block"
                                    name="in_active_campaign"
                                    value={props.triggerSubValue ? props.triggerSubValue : ''}
                                    onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                                <option>Select a status</option>
                                <option value="0">NO</option>
                                <option value="1">YES</option>
                            </select>
                        </div>
                    }
                    {(props.selectedValue == STALE_OPPORTUNITY.HAS_TAG || props.selectedValue == STALE_OPPORTUNITY.DOES_NOT_HAVE_TAG) &&
                    <div className="">
                        <select className="form-control d-block"
                                name="tag"
                                value={props.triggerSubValue ? props.triggerSubValue : ''}
                                onChange={(e) => props.onUnitChange(props.RowIndex, e.target.value, props.triggerType)}>
                            <option>Select a tag</option>
                            {getTagOptions()}
                        </select>
                    </div>
                    }
                </div>
            }

            <div className="campaign__filter_col flex-0">
                <Button onClick={() => props.remover(props.RowIndex, props.triggerType)} data-index={props.RowIndex}>{ iconList.redDeleteIcon }</Button>
            </div>
        </div>
    )

}

export default EachTrigger;
