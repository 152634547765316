import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as ACTION from '../constants/triggerActionTypes'
import * as TRIGGER_API from "../api/triggerApi";


function* triggerWatcher() {
    yield takeEvery(ACTION.FETCH_TRIGGERS, fetchTriggerMiddleware)
    yield takeEvery(ACTION.FETCH_AGENCY_TEMPALTES, fetchAgencyTemplatesMiddleware)
    yield takeEvery(ACTION.CLONE_AGENCY_TEMPLATES, cloneAgencyTemplatesMiddleware)
    yield takeEvery(ACTION.CHANGE_STATUS, changeStatusTriggerMiddleware)
    yield takeEvery(ACTION.DUPLICATE_TRIGGER, duplicateTriggerMiddleware)
    yield takeEvery(ACTION.CLONE_TRIGGER_AND_ASSIGN_USER, cloneTriggerAndAssignUser)
    yield takeEvery(ACTION.MOVE_RULE_TO_FOLDER, moveRuleToFolder)
    yield takeEvery(ACTION.REMOVE_FROM_FOLDER, moveRuleFromFolder)
    yield takeEvery(ACTION.ADD_NEW_RULE, addNewRule)
    yield takeEvery(ACTION.UPDATE_RULE_TITLE, updateTitle)
    yield takeEvery(ACTION.DELETE_TRIGGER, deleteTrigger)
    yield takeEvery(ACTION.CREATE_SHARE_LINK, createShareLink)
    yield takeEvery(ACTION.SHARE_TRIGGER_LINK, shareTriggerLink)
    yield takeEvery(ACTION.ADD_NEW_FOLDER, addNewFolder)
    yield takeEvery(ACTION.EDIT_FOLDER, editFolder)
    yield takeEvery(ACTION.DELETE_FOLDER, deleteFolder)
}

function* fetchTriggerMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.fetchTriggerList, payload);
        yield put({type: ACTION.STORE_TRIGGER_LIST, payload: response.data});
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* fetchAgencyTemplatesMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.fetchAgencyTemplates, payload);
        yield put({type: ACTION.STORE_AGENCY_TEMPLATES, payload: response.data});
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* cloneAgencyTemplatesMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.cloneAgencyTemplates, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* changeStatusTriggerMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.changeTriggerStatus, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* duplicateTriggerMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.duplicateTrigger, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* cloneTriggerAndAssignUser(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.cloneTriggerAndAssignUser, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* moveRuleToFolder(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.moveRuleToFolder, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* moveRuleFromFolder(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.moveRuleFromFolder, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* addNewRule(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.addNewRule, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}


function* updateTitle(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.updateTitle, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* createShareLink(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.createShareLink, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* shareTriggerLink(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.shareTriggerLink, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* deleteTrigger(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.deleteTrigger, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* addNewFolder(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.addNewFolder, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* editFolder(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.editFolder, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* deleteFolder(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.removeFolder, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

export default function* triggerMiddleware() {
    yield all([
        triggerWatcher()
    ])
}



