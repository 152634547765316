import React from "react";
import './App.css';
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import {BrowserRouter as Router} from 'react-router-dom';
import { createGenerateClassName, StylesProvider } from "@material-ui/styles";
import Utils from "./helpers/Utils";

const defaultHistory = createBrowserHistory();
const generateClassName = createGenerateClassName({
    productionPrefix: 'trggr_' + Utils.generateRandomString(6),
});

function App({ history = defaultHistory }) {
    return (
      <Router history={history}>
        <StylesProvider generateClassName={generateClassName}>
          <Routes history={history} />
        </StylesProvider>
      </Router>
    );
}

export default App;
