import { useState } from 'react';
import { Save } from '@material-ui/icons';
import { MenuItem, Select, FormControl } from "@material-ui/core";
import { useDispatch,useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addTriggerRule, fetchTriggers } from '../../actions/triggerAction';
import { DropdownMenuProps } from '../../helpers/Settings';
import { useSimpleValidator } from '../../hooks/useSimpleValidator';
import GlobalModal from '../globals/Modal/GlobalModal';

const ModalAddNewRule = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const simpleValidator = useSimpleValidator();
    const triggerFolders = useSelector(state => state.triggerReducer.triggerFolders);
    const [selectedFolder,setSelectedFolder] = useState(null);
    const [title,setTitle] = useState('');
    const [submiting, setSubmiting] = useState(false);

    const renderList = () => {
        const list = [
            <MenuItem value={null} disabled>
                Select a folder
            </MenuItem>
        ];
        triggerFolders.forEach(triggerFolder => {
            list.push(<MenuItem className="dropdownhelper-menuitem-class" value={ triggerFolder.id }>{triggerFolder.name}</MenuItem>);
        });
        return list;
    }

    const onSubmit = (e) => {
        if(!submiting) {

            setSubmiting(true);
            e.preventDefault();

            if(!simpleValidator.validate()) {
                return;
            }

            try {
                dispatch(addTriggerRule({
                            folder_id : selectedFolder,
                            title : title,
                        },(res) => {
                            setSubmiting(false);

                            if(res.data.success) {
                              window.showNotification('SUCCESS', res.data.message);
                              dispatch(fetchTriggers());
                              history.push('/trigger/settings/'+res.data.data.trigger.id);
                              onCloseForm();
                            } else {
                              window.showNotification('ERROR', res.data.message);
                            }
                        }));
            } catch(e) {
                setSubmiting(false);
            }
        }
    }

    const onCloseForm = () => {
        setSelectedFolder(null);
        setTitle('');
        simpleValidator.hideMessages();
        props.onClose();
    }

    return ( 
        <GlobalModal
            open={props.open}
            onClose={onCloseForm} 
            title={"Add New Trigger"}
            buttonText={submiting ? "Saving" :"Save"}
            buttonIcon={<Save/>}
            onSubmit={onSubmit}
            className="global-small-modal global-xs-modal">
            
            <h3 className="trigger__modal_title mt10pxSpace">Title <span className="red-text">*</span></h3>
            <FormControl className="alt trigger_customFormControl">
                <input 
                    name="title"
                    autoFocus={true}
                    className="trigger__modal_textField" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)}
                    type="text" 
                    placeholder="Enter Title" />
                {simpleValidator.message('title', title, 'required')}
            </FormControl>
            
            <h3 className="trigger__modal_title mt10pxSpace">Add to Folder (optional)</h3>
            <FormControl className="alt trigger_customFormControl">
                <Select
                    MenuProps={DropdownMenuProps}
                    value={selectedFolder} 
                    onChange={ (e) => setSelectedFolder(e.target.value) }
                    displayEmpty
                    className="trigger__select mxW100m15"
                    inputProps={ { 'aria-label': 'Without label' } }
                >
                    {renderList()}
                </Select>
            </FormControl>
        </GlobalModal>
     );
}

export default ModalAddNewRule; 