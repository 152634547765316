import { MenuItem, Select } from '@material-ui/core';
import React from 'react';

export default class GetDurationOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            duration : this.props.value
        }

        this.handleChange = this.handleChange.bind(this);
    }


    _getOptions() {
        let options = [];
        let j = 0;
        // loop through half hour increments
        for(let i = 0; i < 8; i++) {
            for(j = 0; j <= 3; j++ ){
                var minute = (j == 0) ? '00' : j * 15;
                //if (("0"+i+":"+minute) !== '00:00') {
                    options.push(<MenuItem className="dropdownhelper-menuitem-class" key={"0"+i+":"+minute} value={"0"+i+":"+minute}>{"0"+i+":"+minute}</MenuItem>);
                //}
            }
        }
        options.push(<MenuItem className="dropdownhelper-menuitem-class" key={'08:00'} value={'08:00'}>{'08:00'}</MenuItem>);

        return options;
    }

    handleChange(e)
    {
        this.props.durationHandler(e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }


    render() {

        return (

            <React.Fragment>
                <Select 
                    displayEmpty name="duration" 
                    value={this.state.duration} 
                    className={ `trigger__select coloredText` }
                    inputProps={ { 'aria-label': 'Without label' } }
                    onChange={this.handleChange}>
                    {this._getOptions()}
                </Select>
            </React.Fragment>

        );
    }
}
