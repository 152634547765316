import TriggerHead from "./TriggerHead"
import TriggerFolders from "./TriggerFolders"
import TriggerTemplates from "./TriggerTemplates"
import './trigger.css';
import { useEffect } from "react";
import Utils from "../../helpers/Utils";

const Trigger = () => {

    useEffect(() => {
        document.title = `Trigger | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('trigger');
        }
    },[]);

    return ( 
        <div className="trigger">
            <TriggerHead/>
            <TriggerTemplates/>
            <TriggerFolders/>
        </div>
     );
}
 
export default Trigger;