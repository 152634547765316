//Trigger Actions
export const FETCH_TRIGGERS = 'FETCH_TRIGGERS';
export const STORE_TRIGGER_LIST = 'STORE_TRIGGER_LIST';
export const FETCH_AGENCY_TEMPALTES = 'FETCH_AGENCY_TEMPALTES';
export const STORE_AGENCY_TEMPLATES = 'STORE_AGENCY_TEMPLATES';
export const CLONE_AGENCY_TEMPLATES = 'CLONE_AGENCY_TEMPLATES';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const DUPLICATE_TRIGGER = 'DUPLICATE_TRIGGER';
export const CLONE_TRIGGER_AND_ASSIGN_USER = 'CLONE_TRIGGER_AND_ASSIGN_USER';
export const MOVE_RULE_TO_FOLDER = 'MOVE_RULE_TO_FOLDER';
export const REMOVE_FROM_FOLDER = 'REMOVE_FROM_FOLDER';
export const ADD_NEW_RULE = 'ADD_NEW_RULE';
export const UPDATE_RULE_TITLE = 'UPDATE_RULE_TITLE';
export const CREATE_SHARE_LINK = 'CREATE_SHARE_LINK';
export const SHARE_TRIGGER_LINK = 'SHARE_TRIGGER_LINK';
export const DELETE_TRIGGER = 'DELETE_TRIGGER';
export const ADD_NEW_FOLDER = 'ADD_NEW_FOLDER';
export const EDIT_FOLDER = 'EDIT_FOLDER';
export const DELETE_FOLDER = 'DELETE_FOLDER';


//Trigger Setting Actions
export const TRIGGER_SETTING_GET = 'TRIGGER_SETTING_GET';
export const STORE_TRIGGER_SETTING = 'STORE_TRIGGER_SETTING';
export const TRIGGER_SETTING_SAVE = 'TRIGGER_SETTING_SAVE';
export const SET_TRIGGER_ACTION = 'SET_TRIGGER_ACTION';
export const SET_TRIGGER_ACTION_VALUE = 'SET_TRIGGER_ACTION_VALUE';
export const SET_REQUIRED_ERROR = 'SET_REQUIRED_ERROR';
export const REMOVE_ACTION_VALUE = 'REMOVE_ACTION_VALUE';
export const SET_TRIGGER_ITEM = 'SET_TRIGGER_ITEM';
export const SET_TRIGGER_ITEM_VALUE = 'SET_TRIGGER_ITEM_VALUE';
export const SET_TRIGGER_DEFINE_FILTER_VALUE = 'SET_TRIGGER_DEFINE_FILTER_VALUE';
export const RESET_TRIGGER_ACTIONS = 'RESET_TRIGGER_ACTIONS';



//Common Actions
export const FETCH_SUB_USERS = 'FETH_SUB_USERS';
export const STORE_USER_LIST = 'STORE_USER_LIST';
export const FETCH_CUSTOM_FIELDS = 'FETCH_CUSTOM_FIELDS';