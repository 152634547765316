import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import "./switch.css";

const UnCheckedIcon = (props) => {
    return (
        <div
            className="checkoutIcon__off"
            style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                fontSize: 16,
                lineHeight: "24px",
                color: "#F2994A",
                paddingTop: "3px",
            }}>
            {props.label}
        </div>
    );
};
const CheckedIcon = (props) => {
    return (
        <div
            className="checkoutIcon__on"
            style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                height: "100%",
                fontSize: 16,
                lineHeight: "24px",
                color: "#27AE60",
                paddingTop: "3px",
            }}>
            {props.label}
        </div>
    );
};

const CustomSwitch = (props) => {

    const checked = props.checked !== undefined ? props.checked : false;
    
    return (
        <>
            <Switch
                className={checked ? "trigger_active__switch" : "trigger_inactive__switch"}
                onChange={props.onChange}
                checked={checked}
                uncheckedIcon={<UnCheckedIcon label={props.off}/>}
                checkedIcon={<CheckedIcon label={props.on}/>}
                boxShadow="0 0 0px 0px #3bf"
                activeBoxShadow="0 0 0px 0px #3bf"
                onHandleColor="#68EBCA"
                offHandleColor="#FF264A"
                onColor="#27AE60"
                offColor="#FF264A"
                height="30"
                width="65"
            />
        </>
    );
};

export default CustomSwitch;
