import { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchAgencyTemplates } from '../../actions/triggerAction';
import CustomAccordion, { CustomAccordionBody, CustomAccordionHeader } from "../globals/CustomAccordion";
import EachTemplate from './EachTemplate';

const TriggerTemplatesApp = (props) => {

	useEffect(() => {
		props.fetchAgencyTemplates();
	},[]);

	const renderFolders = () => {
		if(props.triggerAgencyTemplateFolders && props.triggerAgencyTemplateFolders.length > 0) {
			return props.triggerAgencyTemplateFolders.map(folder => {
				return <EachTemplate folder={folder}/>
			})
		}

		return <div className="noItems w-100"><p className="emptyRules">Agency trigger template is empty</p></div>
		
	}

    return (
		<CustomAccordion className={"collapsible defaultToggle trigger__agency"}>
			<CustomAccordionHeader className="collapsible-header trigger__header border__bottom flex content-between items-center">
				<span class="trigger__subtitle">Agency Pre-Build Trigger Template Folder</span>
				<svg className="plus__icon" width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">  <line x1="1.5" y1="12.5" x2="18.5" y2="12.5" stroke="#3C7EF3" stroke-width="3" stroke-linecap="round" /> <path d="M10 2L10 22" stroke="#3C7EF3" stroke-width="3" stroke-linecap="round" /> </svg>
                <svg className="minus__icon" width="20" height="3" viewBox="0 0 20 3" fill="none" xmlns="http://www.w3.org/2000/svg"> <line x1="1.5" y1="1.5" x2="18.5" y2="1.5" stroke="#3C7EF3" stroke-width="3" stroke-linecap="round" /> </svg>
			</CustomAccordionHeader>
			<CustomAccordionBody className="collapsible-body">
				<div className="templateItems">
					{
						renderFolders()
					}
				</div>
			</CustomAccordionBody>
		</CustomAccordion> 
    );
}
 
const mapStateToProps = state => ({
    triggerAgencyTemplateFolders: state.triggerReducer.triggerAgencyTemplateFolders
});

const mapDispatchToProps = dispatch => {
    return {
        fetchAgencyTemplates: (params) => dispatch(fetchAgencyTemplates(params))
    };
}

const TriggerTemplates = connect(mapStateToProps, mapDispatchToProps)(TriggerTemplatesApp);
 
export default TriggerTemplates;