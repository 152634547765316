export const DropdownMenuProps = {
    anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
    },
    transformOrigin: {
    vertical: "top",
    horizontal: "left"
    },
    getContentAnchorEl: null
}