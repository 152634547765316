import {connect} from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomSwitch from "../globals/Switch/CustomSwitch";
import { iconList } from "../globals/IconList";
import CoreConstants from "../../constants/CoreConstants";
import { Link } from "react-router-dom";
import { changeStatus, duplicateTrigger, settingSave, settingGet } from "../../actions/triggerAction";
import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { validateActionValue, validateItemValue } from "./TriggerUtils";


const TriggerSettingsHeaderApp = (props)=> {
    const [triggerStatus,setTriggerStatus] = useState(props.triggerRule.status == CoreConstants.TRIGGER_STATUS.STATUS_START);
    const [saving, setSaving] = useState(false);

    const onStatusChange = (status) => {
        try {
            setTriggerStatus(status);
            let changeTo = (status == true) ? CoreConstants.TRIGGER_STATUS.STATUS_START : CoreConstants.TRIGGER_STATUS.STATUS_PAUSE;
            props.changeStatus({ status : changeTo , triggerRuleId : props.triggerRule.id},(res) => {
                if(res.data.success) {
                    window.showNotification('SUCCESS', res.data.message)
                }else {
                    setTriggerStatus(!status);
                    window.showNotification('ERROR', res.data.message)
                }
            });
        } catch (error) {
            console.log(error)
        }
    }

    const duplicateTrigger = () => {
        props.duplicateTrigger({
            triggerRuleId : props.triggerRule.id
        },(res) => {
            if(res.data.success) {
                window.showNotification('SUCCESS', res.data.message)
            }else {
                window.showNotification('ERROR', res.data.message)
            }
        });
    }

    const settingSave = () => {

        if(saving) {
            return "";
        }

        //Validate left side setting. We call it item value
        let validate = validateItemValue(props.triggerItemId, props.triggerItemValue);
        if(!validate.status) {
            window.showNotification('ERROR',validate.message);
            return;
        }

        //Validate right side setting. We call it action value
        validate = validateActionValue(props.triggerActionValue);
        if(!validate.status) {
            window.showNotification('ERROR',validate.message);
            return;
        }

        setSaving(true);
        if(triggerStatus == true || triggerStatus == CoreConstants.TRIGGER_STATUS.STATUS_START) {
            setTriggerStatus(false);
            props.changeStatus({ status : false , triggerRuleId : props.triggerRule.id},(res) => {
                if(res.data.success) {
                    confirmAlert({
                        title: 'Trigger status turned off due to change in your trigger',
                        buttons: [
                            {
                                label: 'OK',
                                onClick: () => {
                                    
                                }
                            }
                        ]
                    });
                }
            });
        }

        props.settingSave(
            {
                triggerActionId : props.triggerActionId,
                triggerActionValue : props.triggerActionValue,
                triggerItemId : props.triggerItemId,
                triggerItemValue : props.triggerItemValue,
                triggerRuleAction : props.triggerRule.rule_trigger_action,
                triggerRuleId : props.triggerRule.id,
                requiredError : props.required_error
            },(res) => {
                window.showNotification(res.data.success ? 'SUCCESS' : 'ERROR', res.data.message)
                setSaving(false);
                if(res.data.success) {
                    props.settingGet({
                        triggerRuleId : props.triggerRule.id
                    })
                }
            }
        );
    };

    return (
        <div className="trigger__settings_topbar trigger__topbar flex items-center content-between" key={triggerStatus}>
            <div className="trigger__settings_left d-flex align-items-center">
                <Link to={'/triggers'}><ArrowBackIcon /></Link> 
                <h2 className="trigger__title">
                    Trigger and Action for <code> &lt;{props.triggerRule.title}&gt;</code>  {!triggerStatus ? <span className="pause-status">Off</span>:<span className="running-status">On</span>}
                </h2>
                <CustomSwitch on="On" off="Off" 
                    checked={triggerStatus}
                    onChange={(status) => onStatusChange(status)}/>
            </div>
            <div className="trigger__btns">
                <button onClick={(e)=> duplicateTrigger()} className="trigger__btn add__newFolder trigger__blueBtn accent--bg--color">
                    {iconList.duplicateIconSize20}
                    Duplicate
                </button>
                <button onClick={()=>settingSave()} disabled={saving} className="trigger__btn add__newBasicRule trigger__blueBtn accent--bg--color">
                    {iconList.saveIcon}
                    {saving ? "Saving..." : "Save"}
                </button>
            </div>
        </div>
    )
};

const mapDispatchToProps = dispatch => {
    return {
        changeStatus: (params,callBack) => dispatch(changeStatus(params,callBack)),
        duplicateTrigger: (params,callBack) => dispatch(duplicateTrigger(params,callBack)),
        settingSave : (params,callBack)  =>  dispatch(settingSave(params,callBack)),
        settingGet : (params,callBack)  =>  dispatch(settingGet(params,callBack)),
    };
}

const mapStateToProps = state => ({
    triggerRule: state.triggerSettingReducer.triggerRule,
    triggerItemId: state.triggerSettingReducer.triggerItemId,
    triggerActionId: state.triggerSettingReducer.triggerActionId,
    triggerActionValue: state.triggerSettingReducer.triggerActionValue,
    triggerItemValue: state.triggerSettingReducer.triggerItemValue,
    required_error: state.triggerSettingReducer.required_error,
    defineFilterTrigger: state.triggerSettingReducer.defineFilterTrigger,
});

const TriggerSettingsHeader = connect(mapStateToProps, mapDispatchToProps)(TriggerSettingsHeaderApp);

export default TriggerSettingsHeader; 