import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as ACTION from '../constants/triggerActionTypes'
import * as COMMON_API from "../api/commonApi";


function* commonWatcher() {
    yield takeEvery(ACTION.FETCH_SUB_USERS, fetchSubUsersMiddleware)
    yield takeEvery(ACTION.FETCH_CUSTOM_FIELDS, fetchCustomFields)
}

function* fetchSubUsersMiddleware(action) {
    const { payload } = action;
    try {
        const response = yield call(COMMON_API.fetchSubUsers, payload);
        yield put({type: ACTION.STORE_USER_LIST, payload: response.data});
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* fetchCustomFields(action) {
    let { payload } = action;
    try {
        const response = yield call(COMMON_API.fetchCustomFields, payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

export default function* commonMiddleware() {
    yield all([
        commonWatcher()
    ])
}