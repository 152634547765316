import * as ACTION from "../constants/triggerActionTypes";

export const fetchTriggers = (payload) => ({
    type: ACTION.FETCH_TRIGGERS,
    payload: payload
});

export const fetchAgencyTemplates = (payload) => ({
    type: ACTION.FETCH_AGENCY_TEMPALTES,
    payload: payload
});

export const cloneAgencyTemplates = (payload,callBack) => ({
    type: ACTION.CLONE_AGENCY_TEMPLATES,
    payload: {payload,callBack}
});


export const changeStatus = (payload,callBack) => ({
    type: ACTION.CHANGE_STATUS,
    payload: {payload,callBack}
});

export const duplicateTrigger = (payload,callBack) => ({
    type: ACTION.DUPLICATE_TRIGGER,
    payload: {payload,callBack}
});

export const cloneTriggerAndAssignUser = (payload,callBack) => ({
    type: ACTION.CLONE_TRIGGER_AND_ASSIGN_USER,
    payload: {payload,callBack}
});

export const moveRuleToFolder = (payload,callBack) => ({
    type: ACTION.MOVE_RULE_TO_FOLDER,
    payload: {payload,callBack}
});

export const removeFromFolder = (payload,callBack) => ({
    type: ACTION.REMOVE_FROM_FOLDER,
    payload: {payload,callBack}
});

export const addTriggerRule = (payload,callBack) => ({
    type: ACTION.ADD_NEW_RULE,
    payload: {payload,callBack}
});

export const updateRuleTitle = (payload,callBack) => ({
    type: ACTION.UPDATE_RULE_TITLE,
    payload: {payload,callBack}
});

export const deleteTrigger = (payload,callBack) => ({
    type: ACTION.DELETE_TRIGGER,
    payload: {payload,callBack}
});

export const createShareLink = (payload,callBack) => ({
    type: ACTION.CREATE_SHARE_LINK,
    payload: {payload,callBack}
});

export const shareTriggerLink = (payload,callBack) => ({
    type: ACTION.SHARE_TRIGGER_LINK,
    payload: {payload,callBack}
});

export const addNewFolder = (payload,callBack) => ({
    type: ACTION.ADD_NEW_FOLDER,
    payload: {payload,callBack}
});

export const editFolder = (payload,callBack) => ({
    type: ACTION.EDIT_FOLDER,
    payload: {payload,callBack}
});

export const deleteFolder = (payload,callBack) => ({
    type: ACTION.DELETE_FOLDER,
    payload: {payload,callBack}
});


/* Setting Actions */

export const settingGet = (payload,callBack) => ({
    type: ACTION.TRIGGER_SETTING_GET,
    payload: {payload,callBack}
});

export const settingSave = (payload,callBack) => ({
    type: ACTION.TRIGGER_SETTING_SAVE,
    payload: {payload,callBack}
});

export const setTriggerAction = (triggerActionId) => ({
    type: ACTION.SET_TRIGGER_ACTION,
    payload: {triggerActionId:triggerActionId}
});

export const setTriggerActionValue = (payload) => ({
    type: ACTION.SET_TRIGGER_ACTION_VALUE,
    payload: {triggerActionValue:payload}
});

export const setRequiredError = (status) => ({
    type: ACTION.SET_REQUIRED_ERROR,
    payload: {status:status}
});

export const removeActionValue = (rowIndex) => ({
    type: ACTION.REMOVE_ACTION_VALUE,
    payload: {rowIndex:rowIndex}
});

export const setTriggerItem = (triggerItemId) => ({
    type: ACTION.SET_TRIGGER_ITEM,
    payload: {triggerItemId : triggerItemId}
});

export const setTriggerItemValue = (payload) => ({
    type: ACTION.SET_TRIGGER_ITEM_VALUE,
    payload: {triggerItemValue : payload}
});

export const setTriggerDefineFilter = (param) => ({
    type: ACTION.SET_TRIGGER_DEFINE_FILTER_VALUE,
    payload : param
});

export const resetTriggerActions = (param) => ({
  type: ACTION.RESET_TRIGGER_ACTIONS,
  payload : param
});