import * as ACTION from "../constants/triggerActionTypes";

const initialState = {
    subUsers : [],
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.STORE_USER_LIST:
            return {...state, subUsers : action.payload.data.users}
        default:
            return state;
    }
}


export default commonReducer;
