import { useState } from 'react';
import { FormControl } from '@material-ui/core';
import GlobalModal from '../globals/Modal/GlobalModal';
import { useDispatch } from 'react-redux';
import { addNewFolder, fetchTriggers } from '../../actions/triggerAction';
import { Save } from '@material-ui/icons';
import { useSimpleValidator } from '../../hooks/useSimpleValidator';

const ModalNewFolder = (props) => {

    const dispatch = useDispatch();
    const simpleValidator = useSimpleValidator();
    let [name,setName] = useState('');

    const onSubmit = (e) => {

        e.preventDefault();
        if(!simpleValidator.validate()) {
            return;
        }

        dispatch(addNewFolder({
            name : name,
        },(res) => {
            if(res.data.success) {
              window.showNotification('SUCCESS', res.data.message);
              closeForm();
            } else {
              window.showNotification('ERROR', res.data.message);
            }
        }));
    }

    const closeForm = () => {
        dispatch(fetchTriggers());
        simpleValidator.hideMessages();
        setName('');
        props.onClose();

    }

    return ( 
        <GlobalModal
            open={props.open}
            onClose={closeForm}
            title={"New Folder"}
            buttonText={"Save"}
            buttonIcon={<Save/>}
            onSubmit={onSubmit}
            className="global-small-modal global-xs-modal">
            <h3 className="trigger__modal_title mt10pxSpace">Name <span className="red-text">*</span></h3>
            <FormControl className="alt trigger_customFormControl">
                <input 
                    autoFocus={true}
                    className="trigger__modal_textField" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)}
                    type="text" 
                    placeholder="Enter Folder Name" />
                {simpleValidator.message('name', name, 'required')}
            </FormControl>      
        </GlobalModal>
     );
}

export default ModalNewFolder; 