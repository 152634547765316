import { MenuItem, Select } from '@material-ui/core';
import React from 'react';

export default class GetTimeOptions extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date : new Date(),
            pad : true,
            time : this.props.value
        }

        this.handleChange = this.handleChange.bind(this);
    }

    _pad(n) {
        return n > 9 ? n : '0' + n;
    }

    _format(d) {

        let h = d.getHours();
        let m = this._pad(d.getMinutes());
        let AMPM = h < 12 ? 'AM' : 'PM';

        // convert to 12 hour clock
        h = (h % 12) || 12;

        // pad with a 0
        if(this.state.pad) {
            h = this._pad(h);
        }

        return `${h}:${m} ${AMPM}`;
    }


    _getOptions() {

        let day = this.state.date;
        let options = [];

        // set to beginning of day
        day.setHours(0, 0, 0, 0);

        // loop through half hour increments
        for(let i = 0; i < 48; i++) {
            let time = new Date(day.getTime() + (i * 1800000));
            let display = this._format(time);
            options.push(<option key={display} value={display}>{display}</option>);
        }

        return options;
    }

    handleChange(e){
        this.props.timeHandler(e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

    render() {

        return (

            <React.Fragment>
                <Select 
                    name="time" 
                    value={this.state.time} 
                    className={ `trigger__select coloredText` }
                    inputProps={ { 'aria-label': 'Without label' } }
                    onChange={this.handleChange}>
                    <MenuItem className="dropdownhelper-menuitem-class" value="07:00 AM">07:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="07:15 AM">07:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="07:30 AM">07:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="07:45 AM">07:45 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="08:00 AM">08:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="08:15 AM">08:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="08:30 AM">08:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="08:45 AM">08:45 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="09:00 AM">09:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="09:15 AM">09:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="09:30 AM">09:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="09:45 AM">09:45 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="10:00 AM">10:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="10:15 AM">10:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="10:30 AM">10:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="10:45 AM">10:45 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="11:00 AM">11:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="11:15 AM">11:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="11:30 AM">11:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="11:45 AM">11:45 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="12:00 PM">12:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="12:15 PM">12:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="12:30 PM">12:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="12:45 PM">12:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="01:00 PM">01:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="01:15 PM">01:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="01:30 PM">01:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="01:45 PM">01:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="02:00 PM">02:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="02:15 PM">02:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="02:30 PM">02:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="02:45 PM">02:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="03:00 PM">03:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="03:15 PM">03:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="03:30 PM">03:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="03:45 PM">03:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="04:00 PM">04:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="04:15 PM">04:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="04:30 PM">04:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="04:45 PM">04:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="05:00 PM">05:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="05:15 PM">05:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="05:30 PM">05:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="05:45 PM">05:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="06:00 PM">06:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="06:15 PM">06:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="06:30 PM">06:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="06:45 PM">06:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="07:00 PM">07:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="07:15 PM">07:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="07:30 PM">07:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="07:45 PM">07:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="08:00 PM">08:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="08:15 PM">08:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="08:30 PM">08:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="08:45 PM">08:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="09:00 PM">09:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="09:15 PM">09:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="09:30 PM">09:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="09:45 PM">09:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="10:00 PM">10:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="10:15 PM">10:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="10:30 PM">10:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="10:45 PM">10:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="11:00 PM">11:00 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="11:15 PM">11:15 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="11:30 PM">11:30 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="11:45 PM">11:45 PM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="12:00 AM">12:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="12:15 AM">12:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="12:30 AM">12:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="12:45 AM">12:45 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="01:00 AM">01:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="01:15 AM">01:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="01:30 AM">01:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="01:45 AM">01:45 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="02:00 AM">02:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="02:15 AM">02:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="02:30 AM">02:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="02:45 AM">02:45 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="03:00 AM">03:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="03:15 AM">03:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="03:30 AM">03:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="03:45 AM">03:45 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="04:00 AM">04:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="04:15 AM">04:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="04:30 AM">04:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="04:45 AM">04:45 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="05:00 AM">05:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="05:15 AM">05:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="05:30 AM">05:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="05:45 AM">05:45 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="06:00 AM">06:00 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="06:15 AM">06:15 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="06:30 AM">06:30 AM</MenuItem>
                    <MenuItem className="dropdownhelper-menuitem-class" value="06:45 AM">06:45 AM</MenuItem>
                </Select>
            </React.Fragment>

        );
    }
}
