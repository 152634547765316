import { ACTION_TYPES, ANNIVERSARY_REMAINDER, APPOINTMENT, CONTACT_REPLIED, SEND_TO, STALE_OPPORTUNITY, TRIGGER_TYPES } from "../../constants/CoreConstants"

export const validateItemValue = (itemId,itemValue) => {

    let errorResponse = { status : false };

    if(itemId === TRIGGER_TYPES.ADDED_CAMPAIGN) {
        if(!Array.isArray(itemValue) && itemValue.campaign_id === null) {
            return {...errorResponse, message : 'Please complete the campaign added filter.' }
        }
    } else if(itemId === TRIGGER_TYPES.ANNIVERSARY_REMAINDER || itemId === TRIGGER_TYPES.BIRTHDAY_REMAINDER) {
        //Anniversary remainder and birthday remainder both has same properties so we keep same validation.
        switch(itemId) {
            case TRIGGER_TYPES.ANNIVERSARY_REMAINDER:
                errorResponse = {...errorResponse, message : 'Please complete the anniversary reminder filter.' };
                break;
            default :
                errorResponse = {...errorResponse, message : 'Please complete the birthday reminder filter.' };
            break;
        }
        
        if(Array.isArray(itemValue) || itemValue.option.length !== itemValue.subOption.length) {
            return errorResponse;
        }

        for(let i = 0;i<itemValue.option.length;i++) {

            //Before/After number of days validation. Checking valid number & more than 0
            if(parseInt(itemValue.option[i]) === ANNIVERSARY_REMAINDER.BEFORE_NO_OF_DAYS || parseInt(itemValue.option[i]) === ANNIVERSARY_REMAINDER.AFTER_NO_OF_DAYS) {
                let valueInt = parseInt(itemValue.subOption[i]);
                if(isNaN(valueInt) || valueInt <= 0) {
                    return {...errorResponse, message : 'Number of days should be valid and more than zero'};
                }
            }

            //Checking other validations
            let isError = 
            (parseInt(itemValue.option[i]) === ANNIVERSARY_REMAINDER.MONTH_IS && itemValue.subOption[i] === 'Select a month') ||
            (parseInt(itemValue.option[i]) === ANNIVERSARY_REMAINDER.DAY_IS && itemValue.subOption[i] === 'Select a day') ||
            (parseInt(itemValue.option[i]) === ANNIVERSARY_REMAINDER.CURRENT_DAY && itemValue.subOption[i] === 'Select time') ||
            (parseInt(itemValue.option[i]) === ANNIVERSARY_REMAINDER.HAS_TAG && Array.isArray(itemValue.subOption[i]) && itemValue.subOption[i].length === 0) ||
            (parseInt(itemValue.option[i]) === ANNIVERSARY_REMAINDER.DOES_NOT_HAS_TAG && Array.isArray(itemValue.subOption[i]) && itemValue.subOption[i].length === 0)
            
            if(isError) return errorResponse;
        }
    } else if (itemId === TRIGGER_TYPES.APPOINTMENT) {

        if(Array.isArray(itemValue) || itemValue.option[0] === 'Select an event' || itemValue.option[0] === '0' || (parseInt(itemValue.option[0]) === APPOINTMENT.APPOINTMENT_STATUS_IS && itemValue.subOption[0] === 'Select a status')) {
            return {...errorResponse, message : 'Please complete the appointment filter.' };
        }

    } else if (itemId === TRIGGER_TYPES.APPOINTMENT_STATUS_CHANGE) {
        errorResponse = {...errorResponse, message : 'Please complete the appointment filter.' }

        if(Array.isArray(itemValue) || itemValue.subOption === undefined) {
            return errorResponse;
        }
    } else if(itemId === TRIGGER_TYPES.CONTACT_REPLIED) {
        errorResponse = {...errorResponse, message : 'Please complete the contact replied filter.' }

        if(Array.isArray(itemValue)) {
            return {status : true};
        }

        if(itemValue.option.length !== itemValue.subOption.length) {
            return errorResponse;
        }

        for(let i = 0;i<itemValue.option.length;i++) {
            let isError = 
                (parseInt(itemValue.option[i]) === CONTACT_REPLIED.IN_CAMPAIGN && (itemValue.subOption[i] === 'Select a campaign' || itemValue.subOption[i] === '')) ||
                (parseInt(itemValue.option[i]) === CONTACT_REPLIED.REPLY_CHANNEL && itemValue.subOption[i] === 'Select reply channel') ||
                (parseInt(itemValue.option[i]) === CONTACT_REPLIED.REPLY_KEYWORD && itemValue.subOption[i].trim() === '') ||
                (parseInt(itemValue.option[i]) === CONTACT_REPLIED.CONTAIN_PHRASE && itemValue.subOption[i].trim() === '') ||
                (parseInt(itemValue.option[i]) === CONTACT_REPLIED.EXACT_MATCH_PHRASE && itemValue.subOption[i].trim() === '')
            
            if(isError) return errorResponse;
        }
    } else if(itemId === TRIGGER_TYPES.ADDED_TAG) {
        errorResponse = {...errorResponse, message : 'Please complete the contact tag filter.' }
        
        if(Array.isArray(itemValue)) {
            return errorResponse;
        }
    } else if(itemId === TRIGGER_TYPES.CUSTOMER_BOOKED_APPOINTMENT) {

        if(!Array.isArray(itemValue) && itemValue.calendar_id === '') {
            return {...errorResponse, message : 'Please complete the appointment filter.' }
        }

    } else if(itemId === TRIGGER_TYPES.DEAL_STATUS_CHANGE) {

        if(!Array.isArray(itemValue) && itemValue.pipeline_id === '') {
            return {...errorResponse, message : 'Please complete the deal won/lost filter.' }
        }
    } else if(itemId === TRIGGER_TYPES.FORM_SUBMISSION) {
        
        if(!Array.isArray(itemValue) && itemValue.form_id === '') {
            return {...errorResponse, message : 'Please complete the form submission filter.' }
        }
    } else if(itemId === TRIGGER_TYPES.INCOMING_CALL) {
        
        if(Array.isArray(itemValue)) {
            return {...errorResponse, message : 'Please complete the incoming call filter.' }
        }
    } else if(itemId === TRIGGER_TYPES.MISSED_CALL) {

        if(Array.isArray(itemValue) || itemValue.virtualNumber === null) {
            return {...errorResponse, message : 'Please complete the missed call filter.' }
        }
    } else if(itemId === TRIGGER_TYPES.OUTGOING_CALL) {
        
        if(Array.isArray(itemValue)) {
            return {...errorResponse, message : 'Please complete the outgoing call filter.' }
        }
    } else if(itemId === TRIGGER_TYPES.STAGE_CHANGE) {

        if(Array.isArray(itemValue)) {
            return {...errorResponse, message : 'Please complete the pipeline stage filter.' }
        }
    } else if(itemId === TRIGGER_TYPES.STALE_OPPORTUNITY) {

        errorResponse = {...errorResponse, message : 'Please complete the stale opportunity filter.' }

        if(Array.isArray(itemValue)) {
            return errorResponse
        }

        if(itemValue.option.length !== itemValue.subOption.length) {
            return errorResponse;
        }

        for(let i = 0;i<itemValue.option.length;i++) {

            if(parseInt(itemValue.option[i]) === STALE_OPPORTUNITY.DURATION_IN_DAYS){
                let valueInt = parseInt(itemValue.subOption[i]);
                if(isNaN(valueInt) || valueInt <= 0) {
                    return {...errorResponse, message : 'Number of days should be valid and more than zero'};
                }
            }
            

            let isError = 
                (parseInt(itemValue.option[i]) === STALE_OPPORTUNITY.IN_ACTIVE_CAMPAIGN && itemValue.subOption[i] === 'Select a status') ||
                (parseInt(itemValue.option[i]) === STALE_OPPORTUNITY.HAS_TAG && (itemValue.subOption[i] === 'Select a tag' || itemValue.subOption[i] === '')) ||
                (parseInt(itemValue.option[i]) === STALE_OPPORTUNITY.DOES_NOT_HAVE_TAG && (itemValue.subOption[i] === 'Select a tag' || itemValue.subOption[i] === ''))
            
            if(isError) return errorResponse;
        }
    } else if(itemId === TRIGGER_TYPES.TASK_REMAINDER) {

        if(Array.isArray(itemValue)) {
            return {...errorResponse, message : 'Please complete the task reminder filter.' }
        }

        let valueInt = parseInt(itemValue.number_of_day);
        if(isNaN(valueInt) || valueInt <= 0) {
            return {...errorResponse, message : 'Number of days should be valid and more than zero'};
        }
    }

    return {status : true}
}

export const validateActionValue = (actionValues) => {

    let errorResponse = { status : false };

    if(actionValues.length === 0) {
        return {...errorResponse, message : 'Action is required to save the trigger.'};
    }

    for(let i = 0;i<actionValues.length;i++) {
        let actionValue = actionValues[i];

        if(actionValue.action_id === ACTION_TYPES.ADD_TO_CAMPAIGN) {
            if(actionValue.campaign_id === null) {
                return {...errorResponse, message : 'Please select a campaign in "Add to campaign" action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.REMOVE_FROM_SINGLE_CAMPAIGN) {
            if(actionValue.campaign_id === null) {
                return {...errorResponse, message : 'Please select a campaign in "Remove from campaign" action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.ADD_TAG) {
            if(actionValue.tag_id === null) {
                return {...errorResponse, message : 'Please select a tag in "Add contact tag" action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.REMOVE_TAG) {
            if(actionValue.tag_id === null) {
                return {...errorResponse, message : 'Please select a tag in "Remove contact tag" action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.SEND_EMAIL) {
            if(actionValue.email_subject === "") {
                return {...errorResponse, message : 'Email subject is required.'};
            } else if(actionValue.email_body === "" || actionValue.email_body === "<p></p>") {
                return {...errorResponse, message : 'Email body is required.'};
            } else if(actionValue.to_type === SEND_TO.ADDRESS && (actionValue.to === null || actionValue.to === '')) {
                return {...errorResponse, message : 'To email is required'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.SEND_SMS) {
            if(actionValue.sms === "") {
                return {...errorResponse, message : 'SMS is required in \'Send SMS\' action.'};
            } else if(actionValue.to_type === SEND_TO.ADDRESS && (actionValue.to === null || actionValue.to === '')) {
                return {...errorResponse, message : 'To number is required in \'Send SMS\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.ADD_NOTE) {
            if(actionValue.note === "") {
                return {...errorResponse, message : 'Note is required in \'Add notes\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.ADD_TASK) {
            if(actionValue.note === "") {
                return {...errorResponse, message : 'Title is required in \'Add activity\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.CHANGE_STAGE) {
            if(actionValue.pipeline_id === null || actionValue.stage_id === null) {
                return {...errorResponse, message : 'Pipeline & Stage required in \'Change stage/Add deal\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.ADD_COLLABORATOR) {
            if(actionValue.collaborator_id === null) {
                return {...errorResponse, message : 'Collaborator required in \'Add collaborator\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.REMOVE_COLLABORATOR) {
            if(actionValue.collaborator_id === null) {
                return {...errorResponse, message : 'Collaborator required in \'Remove collaborator\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.REMOVE_COLLABORATOR) {
            if(actionValue.collaborator_id === null) {
                return {...errorResponse, message : 'Collaborator required in \'Remove collaborator\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.WEBHOOK_URL) {
            if(actionValue.webhook_url === '') {
                return {...errorResponse, message : 'Webhook URL is required in \'Webhook Integration\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.ADD_LIST) {
            if(actionValue.list_id === null) {
                return {...errorResponse, message : 'List is required in \'Add to list\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.REMOVE_LIST) {
            if(actionValue.list_id === null) {
                return {...errorResponse, message : 'List is required in \'Remove from list\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.REMOVE_DEAL) {
            if(actionValue.deleteType === 1 && (actionValue.pipeline_id === null || actionValue.stage_id === null)) {
                return {...errorResponse, message : 'Pipeline & Stage are required in \'Pipeline\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.THIRD_PARTY_INTEGRATION) {
            if(isNaN(actionValue.integration_id)) {
                return {...errorResponse, message : 'Please select a platform in \'Third party integration\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.ADD_SOURCE) {
            if(actionValue.source_id === null) {
                return {...errorResponse, message : 'Please select a source in \'Add source\' action.'};
            }
        } else if(actionValue.action_id === ACTION_TYPES.ZAPIER_WEBHOOK) {
            if(actionValue.zapier_webhook_url === '') {
                return {...errorResponse, message : 'Please input URL in \'Zapier Webhook\' action.'};
            }
        }
        else if(actionValue.action_id === ACTION_TYPES.TWILIO_EMAIL_USER) {
          if(actionValue.value === '') {
            return {...errorResponse, message : 'Please input your email addresses'};
          }
          else{
            let all_emails_string = actionValue.value.replace(/ /g, '');
            let emails =  all_emails_string.split(",");
            let emails_length = emails.length;
            for(let i = 0; i < emails_length; i++){
              if(!validateEmail(emails[i])){
                return {...errorResponse, message : `You should enter valid email. ${emails[i]} is not a valid email.`};
              }
            }          
          }
        }
        else if(actionValue.action_id === ACTION_TYPES.TWILIO_SMS_USER) {
          if(actionValue.value === '') {
            return {...errorResponse, message : 'Please input your contact numbers'};
          }
        }
    }

    return {status : true} 
} 

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};