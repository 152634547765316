import { Button, FormGroup } from '@material-ui/core';
import React from 'react';
import { iconList } from '../globals/IconList';

export default class SelectTask extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: props.taskTitle,
            activeId : props.task_id
        };
        this.toggleClassName= this.toggleClassName.bind(this);

    }

    toggleClassName(name, ID) {
        this.setState({
            active : name,
            activeId : ID
        }, this.props.taskHandler(ID))
    };

    render() {
        return (

            <React.Fragment>
                <div className="activityCustomTab header-buttons pb-3 pt-3">
                    <FormGroup className="btn-group w-100 trigger__btns_group">
                        <Button type="button" startIcon={iconList.callIcon} className={this.state.activeId == '1' ? 'btn btn-default active' : 'btn btn-default'} onClick={ () => this.toggleClassName("call", 1) }
                                data-activity="Call">
                            Call
                        </Button>
                        <Button type="button" startIcon={iconList.appointmentIcon} className={this.state.activeId == '2' ? 'btn btn-default active' : 'btn btn-default'} onClick={ () => this.toggleClassName("meeting", 2) }
                                data-activity="Meeting">
                            Appointment
                        </Button>
                        <Button type="button" startIcon={iconList.taskIcon} className={this.state.activeId == '3' ? 'btn btn-default active' : 'btn btn-default'} onClick={ () => this.toggleClassName("task", 3) }
                                data-activity="Task">
                            Task
                        </Button>
                        <Button type="button" startIcon={iconList.deadLineIcon} className={this.state.activeId == '4' ? 'btn btn-default active' : 'btn btn-default'} onClick={ () => this.toggleClassName("deadline", 4) }
                                data-activity="Deadline">
                            Deadline
                        </Button>
                        <Button type="button" startIcon={iconList.blackEmailIcon} className={this.state.activeId == '5' ? 'btn btn-default active' : 'btn btn-default'} onClick={ () => this.toggleClassName("email", 5) } data-activity="Email">
                            Email
                        </Button>
                        <Button type="button"  startIcon={iconList.followupIcon} className={this.state.activeId == '6' ? 'btn btn-default active' : 'btn btn-default'} onClick={ () => this.toggleClassName("followup", 6) } data-activity="Followup">
                            Followup
                        </Button>
                        <Button type="button" startIcon={iconList.otherIcon} className={this.state.activeId == '7' ? 'btn btn-default active' : 'btn btn-default'} onClick={ () => this.toggleClassName("others", 7) } data-activity="Others">
                            Others
                        </Button>
                    </FormGroup>
                </div>
            </React.Fragment>

        );
    }
}
