import { useState } from 'react';
import GlobalModal from '../globals/Modal/GlobalModal';
import { FormControl } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { editFolder, fetchTriggers } from '../../actions/triggerAction';
import { Save } from '@material-ui/icons';
import { useSimpleValidator } from '../../hooks/useSimpleValidator';

const ModalEditFolder = (props) => {
    const dispatch = useDispatch();
    const simpleValidator = useSimpleValidator();
    let [name,setName] = useState(props.folderName);
    let [open,setOpen] = useState(props.open);

    const onSubmit = (e) => {

        e.preventDefault();
        if(!simpleValidator.validate()) {
            return;
        }

        dispatch(editFolder({
            id : props.folderId,
            title:name
        },(res) => {
            if(res.data.success) {
              window.showNotification('SUCCESS', res.data.message);
              dispatch(fetchTriggers());
              closeModal();
            } else {
              window.showNotification('ERROR', res.data.message);
            }
        }));
    }

    //To close with transitions.
    const closeModal = () => {
        setOpen(false);
        setTimeout(() => {
            props.onClose();
        }, 200);
    }

    return ( 
        <GlobalModal
            open={open} 
            title={"Edit Folder"}
            buttonText={"Save"}
            buttonIcon={<Save/>}
            onSubmit={onSubmit}
            onClose={closeModal}
            className="global-small-modal global-xs-modal">
            <h3 className="trigger__modal_title mt10pxSpace">Name <span className="red-text">*</span></h3>
            <FormControl className="alt trigger_customFormControl">
                <input 
                    autoFocus={true}
                    className="trigger__modal_textField" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)}
                    type="text" 
                    placeholder="Enter Folder Name" />
                {simpleValidator.message('name', name, 'required')}
            </FormControl>      
        </GlobalModal>
     );
}

export default ModalEditFolder; 