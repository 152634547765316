import React from 'react';
import {connect} from "react-redux";
import EachTrigger from "./EachTrigger";
import { setRequiredError, setTriggerItem, setTriggerItemValue ,setTriggerDefineFilter, resetTriggerActions} from '../../actions/triggerAction';
import { ANNIVERSARY_REMAINDER, APPOINTMENT, BIRTHDAY_REMAINDER, TRIGGER_TYPES } from '../../constants/CoreConstants';
import If from 'if-else-react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import {iconList} from '../globals/IconList';
import { DropdownHelper } from '../globals/DropdownHelper';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import IncomingCallFilter from './incomingCall/IncomingCallFilter';
import { Info } from '@material-ui/icons';
import CustomMenuPype from '../common/custom-menu/CustomMenuPype';
import { Icons } from '../../constants/Icons';
import Styles from './new-style.module.css'

const mapStateToProps = state => {
    return {
        loading : state.triggerSettingReducer.loading,
        triggerRule : state.triggerSettingReducer.triggerRule,
        allTriggerItem : state.triggerSettingReducer.allTriggerItem,
        allCampaign : state.triggerSettingReducer.allCampaign,
        allCalendar : state.triggerSettingReducer.allCalendar,
        allPipeline : state.triggerSettingReducer.allPipeline,
        allStage : state.triggerSettingReducer.allStage,
        allTag : state.triggerSettingReducer.allTag,
        allList : state.triggerSettingReducer.allList,
        allForm : state.triggerSettingReducer.allForm,
        data : state.triggerSettingReducer.data,
        allVirtualNumber: state.triggerSettingReducer.allVirtualNumber,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        setTriggerItem: (triggerActionId) => dispatch(setTriggerItem(triggerActionId)),
        setTriggerItemValue: (param) => dispatch(setTriggerItemValue(param)),
        setRequiredError: (param) => dispatch(setRequiredError(param)),
        changeDefineFilter: (param) => dispatch(setTriggerDefineFilter(param)),
        resetTriggerActions: (param) => dispatch(resetTriggerActions(param)),
    };
}

const triggerWithVoiceMail = 2;
const triggerWithOutVoiceMail = 1;

const MISSED_CALL_FILTER_OPTIONS = [
    { label : "Run trigger for missed calls and voicemails", value : triggerWithVoiceMail },
    { label : "Run trigger when I miss a call", value : triggerWithOutVoiceMail },
]

const VIRTUAL_NUMBER_ALL = [
    {label : 'All virtual numbers',value : '-1'}
]

const VIRTUAL_NUMBER_DEFAULT = [
    {label: '{{user_default_number}}', value: '{{user_default_number}}'}
]

const FORM_TYPE = {
    OLD : 1,
    NEW : 2
}

class Trigger extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show : false,
            showSimpleFilter : false,
            selected_trigger_id: 0,
            selected_trigger: {},
            selected_trigger_item_value_length: 1,
            eachTriggerRow : [],
            trigger_value_selected: [],
            trigger_sub_value: [],
            text_reminder_timing_generator : true,
            textReminderChecked: 1,
            selectedValuesToTrigger: [],
            remainingValuesToTrigger: [],
            showFiltering: false,

            added_campaign_option: null,
            selected_campaign_to_take_action_in_campaign_added: null,
            defaultCampaignOptions : [],

            customer_booked_appointment_option: null,
            defaultCalendarOptions : [],
            selected_calendar_to_take_action: null,
            selected_appointment_mark_as_to_take_action: null,

            added_tag_option:null,
            defaultTagEventOptions: [],
            defaultTagOptions : [],
            selected_tag_to_take_action: null,

            task_remainder_option:null,
            defaultTaskRemainderEventOptions: [],
            task_remainder_day: null,

            change_stage_option: null,
            selected_pipeline_to_take_action: null,
            selected_stage_to_take_action: null,
            defaultPipelineOptions : [],
            defaultStageOptions : [],

            deal_pipeline_id:null,
            deal_status:null,
            defaultDealStatusEventOptions: [],

            added_list_option:null,
            defaultListEventOptions: [],
            defaultListOptions : [],
            selected_list_to_take_action: null,

            added_form_option:null,
            defaultFormEventOptions: [],
            defaultFormOptions : [],
            selected_form_to_take_action: null,

            //incoming call
            added_virtual_number_option: null,
            selected_virtual_number_to_take_action: null,
            defaultVirtualNumbers: [],

            //Missed call
            missed_call_option: null,
            selected_missed_call_filter: null,

            /* for twilio error code */
            triggerValue: null,

            firstRender: true
        };
    }


    addSimpleFilter = () => {
        this.setState({
            showSimpleFilter: true
        });
        this.props.setRequiredError(true);
    };

   

    removeSimpleFilter = () => {
        this.setState({
            showSimpleFilter: false,
            selected_campaign_to_take_action_in_campaign_added : null,
            selected_appointment_mark_as_to_take_action : null,
            selected_tag_to_take_action : null,
            selected_form_to_take_action : null,
            deal_pipeline_id : null,
            selected_calendar_to_take_action : null,
            selected_virtual_number_to_take_action : null,
            selected_stage_to_take_action : null,
            selected_pipeline_to_take_action : null
        });
        this.props.setTriggerItemValue([]);
        this.props.setRequiredError(false);
    }

    onValueChange = (index, value, triggerType) => {

        
        let triggerValueSet = [...this.state.trigger_value_selected];
        if(triggerType && triggerType ===TRIGGER_TYPES.CREATE_ACTIVITY){

            value =  parseInt(value);
            if(triggerValueSet.includes(value)){

                window.showNotification('ERROR',"This filter already selected")
            }else{
                triggerValueSet[index] = value;
                this.setState({
                    trigger_value_selected : triggerValueSet
                },()=>this.setTriggerItemValue())
            }
        }else{
            triggerValueSet[index] = value ;
            this.setState({
                trigger_value_selected : triggerValueSet
            },()=>this.setTriggerItemValue())
        }

    }

    onUnitChange = (index, value, reminderType) => {
        let triggerSubValueSet = [...this.state.trigger_sub_value];
        triggerSubValueSet[index] = value;
        this.setState({
            trigger_sub_value : triggerSubValueSet
        },()=>this.setTriggerItemValue())
    }

    setSelectedTagOption = (index, selected_tag_option) => {
        let triggerSubValueSet = [...this.state.trigger_sub_value];
        triggerSubValueSet[index] = selected_tag_option;
        this.setState({
            trigger_sub_value : triggerSubValueSet
        },()=>this.setTriggerItemValue())
    }

    removeFilter = (index, trigger) => {

        if (trigger == this.state.selected_trigger_id) {
            var eachTriggerRow = [...this.state.eachTriggerRow];
            let triggerValueSet = [...this.state.trigger_value_selected];
            let triggerSubValueSet = [...this.state.trigger_sub_value];
            let remainingValuesToTrigger = [...this.state.remainingValuesToTrigger];

            let length = eachTriggerRow.length;
            if (length == 1) {
                this.removeSimpleFilter();
                this.setState({
                    showFiltering: false
                });
            }
            if (index !== -1) {
                eachTriggerRow.splice(index, 1);
                triggerValueSet.splice(index, 1);
                triggerSubValueSet.splice(index, 1);
                remainingValuesToTrigger.splice(index, 1);
                this.setState({
                    eachTriggerRow: eachTriggerRow,
                    trigger_value_selected: triggerValueSet,
                    trigger_sub_value: triggerSubValueSet,
                    remainingValuesToTrigger: remainingValuesToTrigger,
                },()=>this.setTriggerItemValue())

                this.remainingValuesToTrigger(length-1);
            }
        }
    }

    addNewFilter = () => {

        let eachTriggerRow = this.state.eachTriggerRow;
        let rowIndex = eachTriggerRow.length;
        this.remainingValuesToTrigger(rowIndex);

        this.setState({
            eachTriggerRow: this.state.eachTriggerRow.concat(<EachTrigger remover={this.removeFilter}
                                                                                 key={rowIndex}
                                                                                 RowIndex={rowIndex}/>),
            showFiltering: true
        });
        this.addSimpleFilter()
    }

    getEachTrigger = (allTriggerItem) => {
        var data = [];
        if(allTriggerItem.length > 0) {
            data = allTriggerItem.map((data, index) => {
                return <MenuItem 
                className="dropdownhelper-menuitem-class color__trigger_select"
                onClick={()=>{this.changeValue(data.key_index,data)}} 
                value={data.key_index}
                primaryText={data.name}>
                <div className={"flex__column"}>
                    {data.name}
                    <small>{data.description}</small>    
                </div>  
            </MenuItem>
            })
        }
        return data;
    }


    show = (e) =>{
        e.preventDefault();
        this.setState({
            show : !this.state.show
        })
    };

    hide = () =>{
        this.setState({
            show : false
        })
    };

    getTriggerById = (id,triggers) => {
        return triggers.filter( trigger => trigger.id == id);
    }

    remainingValuesToTrigger = (rowIndex) => {

        let remainingValuesToTrigger = [];
        let remainingValueSet = [...this.state.remainingValuesToTrigger];
        if (this.state.trigger_value_selected.length > 0) {
            const values = JSON.parse(this.state.selected_trigger.value);
            remainingValuesToTrigger = values.filter( value => {

                if(this.state.selected_trigger_id == TRIGGER_TYPES.CREATE_ACTIVITY){

                    if(!this.state.trigger_value_selected.includes(parseInt(Object.keys(value)))){
                        return value;
                    }
                }
                else if(!this.state.trigger_value_selected.includes(Object.keys(value).toString())){
                    if(rowIndex > 0) {
                        if (this.state.selected_trigger_id == TRIGGER_TYPES.ANNIVERSARY_REMAINDER && Object.keys(value) == ANNIVERSARY_REMAINDER.BEFORE_NO_OF_DAYS) {
                            if (!this.state.trigger_value_selected.includes(ANNIVERSARY_REMAINDER.AFTER_NO_OF_DAYS.toString()) && !this.state.trigger_value_selected.includes(ANNIVERSARY_REMAINDER.CURRENT_DAY.toString())) {
                                return value;
                            }
                        } else if (this.state.selected_trigger_id == TRIGGER_TYPES.ANNIVERSARY_REMAINDER && Object.keys(value) == ANNIVERSARY_REMAINDER.AFTER_NO_OF_DAYS) {
                            if (!this.state.trigger_value_selected.includes(ANNIVERSARY_REMAINDER.BEFORE_NO_OF_DAYS.toString()) && !this.state.trigger_value_selected.includes(ANNIVERSARY_REMAINDER.CURRENT_DAY.toString())) {
                                return value;
                            }
                        }  else if (this.state.selected_trigger_id == TRIGGER_TYPES.ANNIVERSARY_REMAINDER && Object.keys(value) == ANNIVERSARY_REMAINDER.CURRENT_DAY) {
                            if (!this.state.trigger_value_selected.includes(ANNIVERSARY_REMAINDER.BEFORE_NO_OF_DAYS.toString()) && !this.state.trigger_value_selected.includes(ANNIVERSARY_REMAINDER.AFTER_NO_OF_DAYS.toString())) {
                                return value;
                            }
                        } else if (this.state.selected_trigger_id == TRIGGER_TYPES.BIRTHDAY_REMAINDER && Object.keys(value) == BIRTHDAY_REMAINDER.BEFORE_NO_OF_DAYS) {
                            if (!this.state.trigger_value_selected.includes(BIRTHDAY_REMAINDER.AFTER_NO_OF_DAYS.toString()) && !this.state.trigger_value_selected.includes(BIRTHDAY_REMAINDER.CURRENT_DAY.toString())) {
                                return value;
                            }
                        } else if (this.state.selected_trigger_id == TRIGGER_TYPES.BIRTHDAY_REMAINDER && Object.keys(value) == BIRTHDAY_REMAINDER.AFTER_NO_OF_DAYS) {
                            if (!this.state.trigger_value_selected.includes(BIRTHDAY_REMAINDER.BEFORE_NO_OF_DAYS.toString()) && !this.state.trigger_value_selected.includes(BIRTHDAY_REMAINDER.CURRENT_DAY.toString())) {
                                return value;
                            }
                        }  else if (this.state.selected_trigger_id == TRIGGER_TYPES.BIRTHDAY_REMAINDER && Object.keys(value) == BIRTHDAY_REMAINDER.CURRENT_DAY) {
                            if (!this.state.trigger_value_selected.includes(BIRTHDAY_REMAINDER.BEFORE_NO_OF_DAYS.toString()) && !this.state.trigger_value_selected.includes(BIRTHDAY_REMAINDER.AFTER_NO_OF_DAYS.toString())) {
                                return value;
                            }
                        } else{
                            return value;
                        }
                    }else{
                        
                        return value;
                    }
                }
            });

            remainingValueSet[rowIndex] = remainingValuesToTrigger ;
            this.setState({
                remainingValuesToTrigger : remainingValueSet
            })


        } else {
            remainingValueSet[rowIndex] = JSON.parse(this.state.selected_trigger.value);
            this.setState({
                remainingValuesToTrigger: remainingValueSet
            });
        }
    }

    actionAddToCampaign = (selectedOption) => {
        this.setState({
            selected_campaign_to_take_action_in_campaign_added:selectedOption
        },()=>this.setTriggerItemValue())
    };

    actionChangeMissedCall = (selectedOption) => {
        this.setState({
            selected_missed_call_filter:selectedOption
        },()=>this.setTriggerItemValue())
    };

    actionToChangeAddedCampaignOption = (selectedOption) => {

    }

    actionToChangeMissedCallOption = (selectedOption) => {

    }

    actionToChangeAddedTagOption = (selectedOption) => {
        this.setState({
            added_tag_option: selectedOption
        },()=>this.setTriggerItemValue());
    }

    actionToChangeAddedListOption = (selectedOption) => {
        this.setState({
            added_list_option: selectedOption
        },()=>this.setTriggerItemValue());
    }

    actionToChangeAddedFormOption = (selectedOption) => {
        this.setState({
            added_form_option: selectedOption
        },()=>this.setTriggerItemValue());
    }

    actionToChangeDealStatusOption = (selectedOption) => {
        this.setState({
            deal_status: selectedOption
        },()=>this.setTriggerItemValue());
    }

    actionToChangeTaskRemainderOption = (selectedOption) => {
        this.setState({
            task_remainder_option: selectedOption
        },()=>this.setTriggerItemValue());
    }

    getDefaultOptionForCampaign = () => {
        let allOptions = [];
        if (this.props.allCampaign.length > 0) {
            allOptions = this.props.allCampaign.map(item => {
                return {
                    value: item.id,
                    label: item.title
                }
            });
        }
        this.setState({
            defaultCampaignOptions: allOptions
        })
    }

    actionAddToCalendar = (selectedOption) => {
        this.setState({
            selected_calendar_to_take_action: selectedOption
        },()=>this.setTriggerItemValue())
    };

    actionAppointmentMarkAsDoneChange = (selectedOption) => {
        this.setState({
            selected_appointment_mark_as_to_take_action: selectedOption
        },()=>this.setTriggerItemValue())
    };

    getDefaultOptionForCalendar = () => {
        let allOptions = [];
        if (this.props.allCalendar.length > 0) {
            allOptions = this.props.allCalendar.map((data, index) => {
                return {
                    value: data.id,
                    label: data.title
                }
            });
        }
        this.setState({
            defaultCalendarOptions: allOptions
        });
    }

    /*For tag*/
    actionAddToTag = (selectedOption) => {
        this.setState({
            selected_tag_to_take_action: selectedOption
        },()=>this.setTriggerItemValue())
    };

    getDefaultOptionForTag = (options) => {
        let allOptions = [];
        if (this.props.allTag.length > 0) {
            allOptions = this.props.allTag.map((data) => {
                return {
                    value: data.id,
                    label: data.name
                }
            });
        }
        this.setState({
            defaultTagOptions: allOptions
        });

        let sideOptions = [];
        sideOptions = options.map( (data,index) => {
           return {
               value: index,
               label: data[index]
           }
        });
        this.setState({
            defaultTagEventOptions: sideOptions
        });
    };

    actionAddToList = (selectedOption) => {
        this.setState({
            selected_list_to_take_action: selectedOption
        },()=>this.setTriggerItemValue())
    };

    getDefaultOptionForContactList = (options) => {
        let allOptions = [];
        if (this.props.allList.length > 0) {
            allOptions = this.props.allList.map((data) => {
                return {
                    value: data.id,
                    label: data.title
                }
            });
        }
        this.setState({
            defaultListOptions: allOptions
        });

        let sideOptions = [];
        sideOptions = options.map( (data,index) => {
           return {
               value: index,
               label: data[index]
           }
        });
        this.setState({
            defaultListEventOptions: sideOptions
        });
    };

    actionAddToForm = (selectedOption) => {
        this.setState({
            selected_form_to_take_action: selectedOption
        },()=>this.setTriggerItemValue())
    };

    getDefaultOptionForFormSubmission = (options) => {
        let allOptions = [];
        if (this.props.allForm.length > 0) {
            allOptions = this.props.allForm.map((data) => {
                return {
                    value: data.id,
                    label: data.title,
                    type : data.type
                }
            });
        }
        this.setState({
            defaultFormOptions: allOptions
        });

        let sideOptions = [];
        sideOptions = options.map( (data,index) => {
           return {
               value: index,
               label: data[index]
           }
        });
        this.setState({
            defaultFormEventOptions: sideOptions
        });
    };

    getDefaultOptionForDeal = (options) => {
        let sideOptions = [];
        sideOptions = options.map( (data,index) => {
            return {
                value: index,
                label: data[index]
            }
        });
        this.setState({
            defaultDealStatusEventOptions: sideOptions
        });
    }

    getDefaultOptionForTaskRemainder = (options) => {
        let sideOptions = [];
        sideOptions = options.map( (data,index) => {
           return {
               value: index,
               label: data[index]
           }
        });
        this.setState({
            defaultTaskRemainderEventOptions: sideOptions
        });
    };

    actionAddToTriggerPipeline = (selectedOption) => {
        this.setState({
            selected_pipeline_to_take_action:selectedOption
        });
        if(selectedOption != null && this.state.selected_pipeline_to_take_action != null && this.state.selected_pipeline_to_take_action.value != selectedOption.value){
            this.setState({
                selected_stage_to_take_action: null
            },()=>this.setTriggerItemValue());
        }
        this.getDefaultOptionForStage(selectedOption == null ? null : selectedOption.value);

    };

    actionAddToTriggerDealStatus = (selectedOption) => {
        this.setState({
            deal_pipeline_id:selectedOption
        },()=>this.setTriggerItemValue());

    };

    actionAddToStage = (selectedOption) => {
        this.setState({
            selected_stage_to_take_action:selectedOption
        },()=>this.setTriggerItemValue());
    };

    getDefaultOptionForPipeline = () => {
        let allOptions = [];
        if (this.props.allPipeline.length > 0) {
            allOptions = this.props.allPipeline.map((data, index) => {
                return {
                    value: data.id,
                    label: data.title
                }
            });
        }
        this.setState({
            defaultPipelineOptions: allOptions
        })
    }

    getDefaultOptionForStage = (pipelineId) => {
        let allOptions = [];
        if (this.props.allStage.length > 0) {
            allOptions = this.props.allStage.filter( data => {
                return data.pipeline_id == pipelineId
            }).map(item => {
                return {
                    value: item.id,
                    label: item.stage
                }
            });
        }
        this.setState({
            defaultStageOptions: allOptions
        })
    }


    //! incoming call
    actionAddToTriggerIncomingCall = (selectedOption) => {
        this.setState({
            selected_virtual_number_to_take_action:selectedOption
        },()=>this.setTriggerItemValue())
    };

    getDefaultOptionForIncomingCall = () => {
        let tempVirtualNumbers = [];
        if (this.props.allVirtualNumber.length > 0) {
            tempVirtualNumbers = this.props.allVirtualNumber.map(eachVirtualNumber => {
                return {
                    label: eachVirtualNumber.label,
                    value: eachVirtualNumber.id
                }
            });
        }
        this.setState({
            defaultVirtualNumbers: tempVirtualNumbers
        })
    }

    setTriggerValue = (triggerValue, triggerItemId) => {
        let eachTriggerRow = [];
        let options = [];
        let subOptions = [];
        triggerValue.map( (value,index) => {
            options.push(value.option.toString());
            if(triggerItemId == TRIGGER_TYPES.ANNIVERSARY_REMAINDER || triggerItemId == TRIGGER_TYPES.BIRTHDAY_REMAINDER) {
                if(value.option == ANNIVERSARY_REMAINDER.HAS_TAG || value.option == ANNIVERSARY_REMAINDER.DOES_NOT_HAS_TAG) {
                    let tagString = value.subOption;
                    if(tagString != '' || tagString != null) {
                        let subOption = [];
                        let tagArray = tagString.split(",");
                        tagArray.map((singleTag,index) => {
                            let tagData = this.getTriggerById(singleTag, this.props.allTag);
                            if(tagData[0]){
                                subOption.push({
                                    label: tagData[0].name,
                                    value: tagData[0].id
                                });
                            }
                        })
                        subOptions.push(subOption);
                    }else{
                        subOptions.push(null);
                    }
                } else {
                    if(value.subOption != "" && value.subOption != null){
                        subOptions.push(value.subOption.toString());
                    }else{
                        subOptions.push(value.subOption);
                    }
                }
            } else {
                if(value.subOption !== "" && value.subOption != null){
                    subOptions.push(value.subOption.toString());
                }else{
                    subOptions.push(value.subOption);
                }
            }
            eachTriggerRow = eachTriggerRow.concat(<EachTrigger remover={this.removeFilter} key={index} RowIndex={index}/>)
        });
        this.setState({
            eachTriggerRow: eachTriggerRow,
            trigger_value_selected: options,
            trigger_sub_value: subOptions,
            showFiltering: true
        },()=>this.setTriggerItemValue());
    }

    setRemainingValueAtFirst = (optionValues, triggerValue,trigger_item_id) => {


        if(optionValues.length > 0) {
            let remainingValueSet = [];
            optionValues.map((option, index) => {
                if(index > 0) {
                    let indexValue = optionValues.filter((item, optionIndex) => optionIndex < index).map( v => v.option);
                    let remainingValuesToTrigger = triggerValue.filter(value => {
                        if (!indexValue.includes(parseInt(Object.keys(value).toString()))) {
                            return value;
                        }
                    });
                    remainingValueSet[index] = remainingValuesToTrigger;
                }else{
                    remainingValueSet[index] = triggerValue;
                }

            });
            this.setState({
                remainingValuesToTrigger: remainingValueSet
            })
        }
    }

    componentDidMount(){
        const {triggerRule} = this.props;

        if(triggerRule.rule_trigger_action != null) {
            const trigger_item_id = triggerRule.rule_trigger_action.user_trigger_item_id;
            const trigger_item_value = triggerRule.rule_trigger_action.user_trigger_item_value;
            let data = this.getTriggerById(trigger_item_id, this.props.allTriggerItem);
            this.changeValue(trigger_item_id, data[0]);

            if(trigger_item_value) {
                if (trigger_item_id === TRIGGER_TYPES.ADDED_CAMPAIGN) {

                    //If trigger comes from snapshot
                    if (trigger_item_value.campaign_id === null) {
                        this.setState({
                            selected_campaign_to_take_action_in_campaign_added: { value : null },
                            showSimpleFilter: true
                        },()=>this.setTriggerItemValue())
                    } else {
                        let campaignData = this.getTriggerById(trigger_item_value.campaign_id, this.props.allCampaign);
                        if(campaignData[0]){
                            this.setState({
                                selected_campaign_to_take_action_in_campaign_added: {
                                    label: campaignData[0].title,
                                    value: trigger_item_value.campaign_id
                                },
                                added_campaign_option: trigger_item_value.option,
                                showSimpleFilter: true
    
                            },()=>this.setTriggerItemValue())
                        }
                    }
                }
                else if (trigger_item_id === TRIGGER_TYPES.CUSTOMER_BOOKED_APPOINTMENT) {
                    let calendarData = this.getTriggerById(trigger_item_value.calendar_id, this.props.allCalendar);
                    if(calendarData[0] || trigger_item_value.calendar_id === ''){
                        this.setState({
                            selected_calendar_to_take_action: (calendarData[0] ? { label: calendarData[0].title, value: trigger_item_value.calendar_id } : null),
                            customer_booked_appointment_option: trigger_item_value.option,
                            showSimpleFilter: true

                        },()=>this.setTriggerItemValue())
                    }

                }

                else if (trigger_item_id === TRIGGER_TYPES.ADDED_TAG) {
                    let tagData = this.getTriggerById(trigger_item_value.tag_id, this.props.allTag);
                    if(tagData[0] || trigger_item_value.tag_id === ""){
                        let optionTag = "Tag Added";
                        if(trigger_item_value.option == 1){
                            optionTag = "Tag Removed";
                        }
                        this.setState({
                            selected_tag_to_take_action: (tagData[0] ? { label: tagData[0].name, value: trigger_item_value.tag_id } : null),
                            added_tag_option: { label: optionTag, value: trigger_item_value.option },
                            showSimpleFilter: true

                        },()=>this.setTriggerItemValue())
                    }
                }

                /*else if (trigger_item_id === TRIGGER_TYPES.CONTACT_LIST) {
                    let listData = this.getTriggerById(trigger_item_value.contact_list_id, this.props.allList);
                    if(listData[0]){
                        let optionList = "Removed from Contact List";
                        if(trigger_item_value.option == 1){
                            optionList = "Added in Contact List";
                        }
                        this.setState({
                            selected_list_to_take_action: {
                                label: listData[0].title,
                                value: trigger_item_value.contact_list_id
                            },
                            added_list_option: {
                                /!*label: JSON.parse(data[0].value)[trigger_item_value.option],*!/
                                label: optionList,
                                value: trigger_item_value.option
                            },
                            showSimpleFilter: true

                        },()=>this.setTriggerItemValue())
                    }
                }*/

                else if (trigger_item_id === TRIGGER_TYPES.FORM_SUBMISSION) {
                    let formData = this.props.allForm.filter( trigger => trigger_item_value.type ? trigger.id == trigger_item_value.form_id && trigger.type == trigger_item_value.type : trigger.id == trigger_item_value.form_id );
                    if(formData[0] || trigger_item_value.form_id === ""){
                        let optionForm = "Form Submit";
                        if(trigger_item_value.option == 1){
                            optionForm = "Resubmit form";
                        }
                        this.setState({
                            selected_form_to_take_action: formData[0] ? { label: formData[0].title, value: trigger_item_value.form_id, type: formData[0].type ? formData[0].type : FORM_TYPE.OLD } : null,
                            added_form_option: {
                                label: optionForm,
                                value: trigger_item_value.option,
                            },
                            showSimpleFilter: true

                        },()=>this.setTriggerItemValue())
                    }
                }

                else if (trigger_item_id === TRIGGER_TYPES.DEAL_STATUS_CHANGE) {
                    let optionTag = "Deal Lost";
                    if(trigger_item_value.option == 1){
                        optionTag = "Deal Won";
                    }
                    let PipelineData = this.getTriggerById(trigger_item_value.pipeline_id, this.props.allPipeline);
                    if(PipelineData[0] || trigger_item_value.pipeline_id === ''){
                        this.setState({
                            deal_pipeline_id: (PipelineData[0] ? { label: PipelineData[0].title, value: trigger_item_value.pipeline_id } : null),
                        },()=>this.setTriggerItemValue());
                    }
                    this.setState({
                        deal_status: {
                            /*label: JSON.parse(data[0].value)[trigger_item_value.option],*/
                            label: optionTag,
                            value: trigger_item_value.option
                        },
                        showSimpleFilter: true

                    },()=>this.setTriggerItemValue())
                }
                
                //! incoming call
                else if (trigger_item_id === TRIGGER_TYPES.INCOMING_CALL || trigger_item_id === TRIGGER_TYPES.OUTGOING_CALL) {
                    let virtualNumbers = this.getTriggerById(trigger_item_value.virtual_number_id, this.props.allVirtualNumber);
                    if(virtualNumbers[0] || trigger_item_value.virtual_number_id === '' || trigger_item_value.virtual_number_id === VIRTUAL_NUMBER_DEFAULT[0].value){
                        if(trigger_item_value.virtual_number_id === VIRTUAL_NUMBER_DEFAULT[0].value){
                            this.setState({
                                selected_virtual_number_to_take_action: VIRTUAL_NUMBER_DEFAULT[0],
                                added_virtual_number_option: trigger_item_value.option,
                                showSimpleFilter: true

                            },()=>this.setTriggerItemValue())
                        }else{
                            this.setState({
                                selected_virtual_number_to_take_action: virtualNumbers[0] ? { label: virtualNumbers[0].label, value: virtualNumbers[0].id } : null,
                                added_virtual_number_option: trigger_item_value.option,
                                showSimpleFilter: true

                            },()=>this.setTriggerItemValue())    
                        }
                        
                    }
                }
                else if (trigger_item_id === TRIGGER_TYPES.TASK_REMAINDER) {
                    let content = 'Before No of Days';
                    if(trigger_item_value.option == 1){
                        content = 'After No of Days';
                    }
                    this.setState({
                        task_remainder_option: {
                            label: content,
                            value: trigger_item_value.option
                        },
                        task_remainder_day: trigger_item_value.number_of_day,
                        showSimpleFilter: true
                    },()=>this.setTriggerItemValue())
                }


                else if (trigger_item_id === TRIGGER_TYPES.CONTACT_REPLIED || trigger_item_id === TRIGGER_TYPES.ANNIVERSARY_REMAINDER || trigger_item_id === TRIGGER_TYPES.BIRTHDAY_REMAINDER || trigger_item_id === TRIGGER_TYPES.APPOINTMENT || trigger_item_id === TRIGGER_TYPES.STALE_OPPORTUNITY) {
                    this.setRemainingValueAtFirst(trigger_item_value,JSON.parse(data[0].value));
                    this.setTriggerValue(trigger_item_value,trigger_item_id);
                } 
                else if (trigger_item_id === TRIGGER_TYPES.STAGE_CHANGE) {

                    let PipelineData = this.getTriggerById(trigger_item_value.pipeline_id, this.props.allPipeline);
                    let StageData = this.getTriggerById(trigger_item_value.stage_id, this.props.allStage);
                    if(PipelineData[0] || trigger_item_value.pipeline_id === ''){
                        this.setState({
                            selected_pipeline_to_take_action: PipelineData[0] ? { label: PipelineData[0].title, value: trigger_item_value.pipeline_id } : null,
                            change_stage_option: trigger_item_value.option,
                            showSimpleFilter: true
                        },()=>this.setTriggerItemValue());
                    }
                    if(StageData[0]){
                        this.setState({
                            selected_stage_to_take_action: {
                                label: StageData[0].stage,
                                value: trigger_item_value.stage_id
                            }
                        },()=>this.setTriggerItemValue());
                    }
                    this.getDefaultOptionForStage(trigger_item_value.pipeline_id);
                }

                else if (trigger_item_id === TRIGGER_TYPES.APPOINTMENT_STATUS_CHANGE) {
                    this.setState({
                        selected_appointment_mark_as_to_take_action: {
                            label: "Done",
                            value: 0
                        },
                        showSimpleFilter: true

                    },()=>this.setTriggerItemValue())

                }
                
                else if (trigger_item_id === TRIGGER_TYPES.CREATE_ACTIVITY) {

                    let remainingValueSet = []

                    if (trigger_item_value && trigger_item_value.option.length > 0 ){

                        let val = [...JSON.parse(data[0].value)]
                        let reomveIndex = []
                        for(let i=0;i< trigger_item_value.option.length; i++){
                            if(i===0){
                                remainingValueSet[i] = val;
                            }
                            else{
                                for(let j in val){
                                    if(val[j][trigger_item_value.option[i-1]]){
                                        reomveIndex.push(parseInt(j))
                                        console.log(reomveIndex)
                                        break;
                                    }
                                }
                                
                                remainingValueSet[i] = val.filter(function(value, index) {
                                    
                                    return reomveIndex.indexOf(index) === -1;
                                })
                            }
                        }
                        this.setState({
                            trigger_value_selected : trigger_item_value.option, showFiltering: true, selected_value: data[0],
                            eachTriggerRow: trigger_item_value.option,remainingValuesToTrigger:remainingValueSet
                        },()=>this.setTriggerItemValue())
                    }

                }

                else if (trigger_item_id === TRIGGER_TYPES.MISSED_CALL) {
                    let missedCallData = MISSED_CALL_FILTER_OPTIONS.filter( filter => filter.value == trigger_item_value.subOption);
                    if(missedCallData[0]){
                        this.setState({
                            selected_missed_call_filter: {
                                label: missedCallData[0].label,
                                value: trigger_item_value.subOption
                            },
                            missed_call_option: trigger_item_value.option,
                            showSimpleFilter: true

                        },()=>this.setTriggerItemValue())
                    }

                    let virtualNumbers = this.props.allVirtualNumber.filter(filter => filter.label == trigger_item_value.virtualNumber);
                    let virtualNumber = null;
                    if(virtualNumbers[0]) {
                        virtualNumber = {
                            label: virtualNumbers[0].label,
                            value: virtualNumbers[0].id,
                        }
                    } else if(trigger_item_value.virtualNumber === '-1') {
                        virtualNumber = {
                            label: VIRTUAL_NUMBER_ALL[0].label,
                            value: VIRTUAL_NUMBER_ALL[0].value,
                        }
                    }
                    else if(trigger_item_value.virtualNumber === VIRTUAL_NUMBER_DEFAULT[0].value) {
                        virtualNumber = {
                            label: VIRTUAL_NUMBER_DEFAULT[0].label,
                            value: VIRTUAL_NUMBER_DEFAULT[0].value,
                        }
                    }
                    console.log(this.state.selected_virtual_number_to_take_action)
                     console.log(trigger_item_value.virtualNumber)

                    console.log('-------9999999-------')
                    
                    this.setState({
                        selected_virtual_number_to_take_action: virtualNumber
                    },()=>this.setTriggerItemValue())
                }
            }
        }
    }

    changeValue =  (id,trigger) =>{
        if(id !== this.state.selected_trigger_id) {
            const old_trigger_value = this.state.selected_trigger_id;
            this.setState({triggerValue: null})
            this.props.setTriggerItemValue([]);
            this.props.setTriggerItem(id);
            this.props.changeDefineFilter(id)
            
            this.setState({
                selected_trigger_id: id,
                selected_trigger: trigger,
                selected_trigger_item_value_length: 1,
                showFiltering: false,
                showSimpleFilter: false,
                added_campaign_option:null,
                selected_campaign_to_take_action_in_campaign_added: null,
                defaultCampaignOptions : [],

                added_tag_option:null,
                defaultTagEventOptions: [],
                selected_tag_to_take_action: null,
                defaultTagOptions : [],

                task_remainder_option:null,
                defaultTaskRemainderEventOptions: [],
                task_remainder_day: null,

                customer_booked_appointment_option: null,
                defaultCalendarOptions : [],
                selected_calendar_to_take_action: null,

                change_stage_option:null,
                selected_pipeline_to_take_action: null,
                selected_stage_to_take_action: null,
                defaultPipelineOptions : [],
                defaultStageOptions : [],

                eachTriggerRow : [],
                trigger_value_selected: [],
                trigger_sub_value: [],

                remainingValuesToTrigger: [],

                deal_pipeline_id:null,
                deal_status:null,
                defaultDealStatusEventOptions: [],

                added_list_option:null,
                defaultListEventOptions: [],
                defaultListOptions : [],
                selected_list_to_take_action: null,

                added_form_option:null,
                defaultFormEventOptions: [],
                defaultFormOptions : [],
                selected_form_to_take_action: null,

                missed_call_option:null,
                selected_missed_call_filter: null,
            });

            if (id === TRIGGER_TYPES.ADDED_CAMPAIGN) {
                this.setState({added_campaign_option:0});
                this.getDefaultOptionForCampaign();
            } else if (id === TRIGGER_TYPES.STAGE_CHANGE) {
                this.setState({change_stage_option:0});
                this.getDefaultOptionForPipeline();
            } else if (id === TRIGGER_TYPES.CUSTOMER_BOOKED_APPOINTMENT) {
                this.setState({customer_booked_appointment_option:0});
                this.getDefaultOptionForCalendar();
            } else if (id === TRIGGER_TYPES.ADDED_TAG) {
                this.getDefaultOptionForTag(JSON.parse(trigger.value));
            }
            /*else if (id === TRIGGER_TYPES.CONTACT_LIST) {
                this.getDefaultOptionForContactList(JSON.parse(trigger.value));
            }*/
            else if (id === TRIGGER_TYPES.FORM_SUBMISSION) {
                this.getDefaultOptionForFormSubmission(JSON.parse(trigger.value));
            } else if (id === TRIGGER_TYPES.DEAL_STATUS_CHANGE) {
                this.getDefaultOptionForPipeline();
                this.getDefaultOptionForDeal(JSON.parse(trigger.value));
            } else if (id === TRIGGER_TYPES.TASK_REMAINDER) {
                this.getDefaultOptionForTaskRemainder(JSON.parse(trigger.value));
            } else if (id === TRIGGER_TYPES.CALENDLY_APPOINTMENT_BOOKED) {

            } else if(id === TRIGGER_TYPES.INCOMING_CALL || id === TRIGGER_TYPES.OUTGOING_CALL) {
                this.setState({added_virtual_number_option:0});
                this.getDefaultOptionForIncomingCall();
            } else if(id === TRIGGER_TYPES.MISSED_CALL) {
                this.setState({missed_call_option:1});
                this.getDefaultOptionForIncomingCall();
            }
            else if(
              id === TRIGGER_TYPES.SMS_ACCOUNT_SUSPENDED ||
              id === TRIGGER_TYPES.TWILIO_MESSAGE_FILTER ||
              id === TRIGGER_TYPES.TWILIO_DAILY_MESSAGE_CAP
            ) {
              this.setState({triggerValue: id})
            } else{
              // let length = JSON.parse(trigger.value).length;
              // this.setState({
              //   selected_trigger_item_value_length: length,
              // });
              let length;
              try{
                length = JSON.parse(trigger.value).length;
              }
              catch(e){
                length = 0;
              }
              this.setState({
                selected_trigger_item_value_length: length,
              });
            }

            if(!this.state.firstRender){
              if(id !== old_trigger_value){
                this.props.resetTriggerActions(true)
              }
              // if(
              //   (id === TRIGGER_TYPES.SMS_ACCOUNT_SUSPENDED ||
              //   id === TRIGGER_TYPES.TWILIO_MESSAGE_FILTER ||
              //   id === TRIGGER_TYPES.TWILIO_DAILY_MESSAGE_CAP)
              //   &&
              //   (old_trigger_value !== TRIGGER_TYPES.SMS_ACCOUNT_SUSPENDED &&
              //   old_trigger_value !== TRIGGER_TYPES.TWILIO_MESSAGE_FILTER &&
              //   old_trigger_value !== TRIGGER_TYPES.TWILIO_DAILY_MESSAGE_CAP)
              // ){
              //   this.props.resetTriggerActions(true)
              // }
              // else if(
              //   (old_trigger_value === TRIGGER_TYPES.SMS_ACCOUNT_SUSPENDED ||
              //   old_trigger_value === TRIGGER_TYPES.TWILIO_MESSAGE_FILTER ||
              //   old_trigger_value === TRIGGER_TYPES.TWILIO_DAILY_MESSAGE_CAP)
              //   &&
              //   (id !== TRIGGER_TYPES.SMS_ACCOUNT_SUSPENDED &&
              //   id !== TRIGGER_TYPES.TWILIO_MESSAGE_FILTER &&
              //   id !== TRIGGER_TYPES.TWILIO_DAILY_MESSAGE_CAP)
              // ){
              //   this.props.resetTriggerActions(true)
              // }
            }
            else{
              this.setState({firstRender: false})
            }
        }
        this.hide();
    };

    setTriggerItemValue = () => {
        const {selected_trigger_id} = this.state;
        if (selected_trigger_id === TRIGGER_TYPES.ADDED_CAMPAIGN) {
            if (this.state.selected_campaign_to_take_action_in_campaign_added !== null){
                this.props.setTriggerItemValue({
                    option: this.state.added_campaign_option,
                    campaign_id:this.state.selected_campaign_to_take_action_in_campaign_added.value,
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        }
        else if (selected_trigger_id === TRIGGER_TYPES.ADDED_TAG) {
            if (this.state.added_tag_option !== null && this.state.selected_tag_to_take_action !== null){
                this.props.setTriggerItemValue({
                    option: this.state.added_tag_option.value,
                    tag_id:this.state.selected_tag_to_take_action.value,
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        }
        /*else if (selected_trigger_id === TRIGGER_TYPES.CONTACT_LIST) {
            if (this.state.added_list_option !== null && this.state.selected_list_to_take_action !== null){
                this.props.setTriggerItemValue({
                    option: this.state.added_list_option.value,
                    contact_list_id:this.state.selected_list_to_take_action.value,
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        }*/
        else if (selected_trigger_id === TRIGGER_TYPES.FORM_SUBMISSION) {
            if (this.state.added_form_option !== null){
                this.props.setTriggerItemValue({
                    option: this.state.added_form_option.value,
                    form_id: (this.state.selected_form_to_take_action !== null ? this.state.selected_form_to_take_action.value : ''),
                    type : (this.state.selected_form_to_take_action !== null ? (this.state.selected_form_to_take_action.type ? this.state.selected_form_to_take_action.type : FORM_TYPE.OLD) : '')
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        }
        else if (selected_trigger_id === TRIGGER_TYPES.DEAL_STATUS_CHANGE) {
            if (this.state.deal_status !== null){
                this.props.setTriggerItemValue({
                    option: this.state.deal_status.value,
                    pipeline_id: this.state.deal_pipeline_id !== null ? this.state.deal_pipeline_id.value : '',
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        }
        else if (selected_trigger_id === TRIGGER_TYPES.TASK_REMAINDER) {
            if (this.state.task_remainder_option !== null && this.state.task_remainder_day !== null){
                this.props.setTriggerItemValue({
                    option: this.state.task_remainder_option.value,
                    number_of_day:this.state.task_remainder_day,
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        }
        else if (selected_trigger_id === TRIGGER_TYPES.CUSTOMER_BOOKED_APPOINTMENT) {
            if (this.state.customer_booked_appointment_option !== null){
                this.props.setTriggerItemValue({
                    option: this.state.customer_booked_appointment_option,
                    calendar_id:this.state.selected_calendar_to_take_action === null ? "" : this.state.selected_calendar_to_take_action.value
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        }
        else if (selected_trigger_id === TRIGGER_TYPES.STAGE_CHANGE) {
            if (this.state.selected_pipeline_to_take_action !== null && this.state.selected_stage_to_take_action){
                this.props.setTriggerItemValue({
                    option: this.state.change_stage_option,
                    pipeline_id:this.state.selected_pipeline_to_take_action.value,
                    stage_id:this.state.selected_stage_to_take_action.value
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        }
        else if (selected_trigger_id === TRIGGER_TYPES.CONTACT_REPLIED || selected_trigger_id === TRIGGER_TYPES.ANNIVERSARY_REMAINDER || selected_trigger_id === TRIGGER_TYPES.BIRTHDAY_REMAINDER
            || selected_trigger_id === TRIGGER_TYPES.STALE_OPPORTUNITY || selected_trigger_id === TRIGGER_TYPES.APPOINTMENT) {
            if (this.state.trigger_value_selected.length > 0 && this.state.trigger_sub_value.length > 0){
                this.props.setTriggerItemValue({
                    option: this.state.trigger_value_selected,
                    subOption:this.state.trigger_sub_value,
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        }
        
        else if (selected_trigger_id ===  TRIGGER_TYPES.CREATE_ACTIVITY) {

            if (this.state.trigger_value_selected.length > 0){
                this.props.setTriggerItemValue({
                    option: this.state.trigger_value_selected,
                    // subOption:this.state.trigger_sub_value,
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        }
        
        //! Incoming call
        else if (selected_trigger_id === TRIGGER_TYPES.INCOMING_CALL || selected_trigger_id === TRIGGER_TYPES.OUTGOING_CALL) {
            if (this.state.selected_virtual_number_to_take_action){
                this.props.setTriggerItemValue({
                    option: this.state.added_virtual_number_option,
                    virtual_number_id:this.state.selected_virtual_number_to_take_action.value,
                });
            } else {
                this.props.setTriggerItemValue([]);
            }
        }

        else if (selected_trigger_id === TRIGGER_TYPES.APPOINTMENT_STATUS_CHANGE) {
            if (this.state.selected_appointment_mark_as_to_take_action !== null){
                this.props.setTriggerItemValue({
                    option: 0,
                    subOption: this.state.selected_appointment_mark_as_to_take_action.id
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        } else if (selected_trigger_id === TRIGGER_TYPES.MISSED_CALL) {
            if (this.state.selected_missed_call_filter !== null){

                let selectedVirtualNumber = null;
                if(this.state.selected_virtual_number_to_take_action !== null) {
                    if(this.state.selected_virtual_number_to_take_action.value === '-1') {
                        selectedVirtualNumber = '-1';
                    } 
                    else if(this.state.selected_virtual_number_to_take_action.value ===  VIRTUAL_NUMBER_DEFAULT[0].value) {
                        selectedVirtualNumber = VIRTUAL_NUMBER_DEFAULT[0].value

                    }else {
                        selectedVirtualNumber = this.state.selected_virtual_number_to_take_action.label;
                    }
                }

                this.props.setTriggerItemValue({
                    option: this.state.missed_call_option,
                    subOption :this.state.selected_missed_call_filter.value,
                    virtualNumber : selectedVirtualNumber
                });
            }else{
                this.props.setTriggerItemValue([]);
            }
        }
    }

    onChangeHandle = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        },()=>this.setTriggerItemValue());
    }

    renderTriggerItemLabel = () => {
        if(JSON.stringify(this.state.selected_trigger) !== JSON.stringify({})){
            return (
                <div className={Styles.labelWrp}>
                  <div className={Styles.labelInfo}>
                    <span className={Styles.labelTitle}>{this.state.selected_trigger.name}</span>
                    <small className={Styles.labelDescription}>{this.state.selected_trigger.description}</small>
                  </div>
                  <span className={Styles.iconWrp}>{Icons.arrow_down}</span>
                </div>
            );
        }
        return (
            <div className={Styles.labelWrp}>
              <span>Select trigger item</span>
              <span className={Styles.iconWrp}>{Icons.arrow_down}</span>
            </div>
        );
    }

    render() {
        const {allTriggerItem} = this.props;
        const {selected_trigger_id} = this.state;
        let showClass = "";

        if(this.state.show){
            showClass = "dropdown-wrapper open"
        } else {
            showClass = "dropdown-wrapper"
        }

        // For filter

        let newFilterRowData = [];
        if(this.state.eachTriggerRow.length > 0){
            this.state.eachTriggerRow.map((row, index) => {
                newFilterRowData.push(
                    <EachTrigger 
                        selectedValue={this.state.trigger_value_selected[index]}
                        triggerValues={JSON.parse(this.state.selected_trigger.value)}
                        onValueChange={this.onValueChange}
                        onUnitChange={this.onUnitChange}
                        setSelectedTagOption={this.setSelectedTagOption}
                        allCampaign={this.props.allCampaign}
                        allVirtualNumber={this.props.allVirtualNumber}
                        allPipeline={this.props.allPipeline}
                        allStage={this.props.allStage}
                        allTag={this.props.allTag}
                        allCalendar={this.props.allCalendar}
                        triggerSubValue={this.state.trigger_sub_value[index]}
                        remover={this.removeFilter}
                        triggerType={selected_trigger_id}
                        remainingValuesToTrigger={this.state.remainingValuesToTrigger[index]}
                        key={index}
                        currentTotalRow={this.state.eachTriggerRow.length}
                        RowIndex={index}
                    />
                );
            });
        }

        let oldVirtualNumber = this.state.defaultVirtualNumbers;
        let newVirtualNumber = [...VIRTUAL_NUMBER_DEFAULT, ...oldVirtualNumber];
        // let newVirtualNumber = oldVirtualNumber;

        return (
            <>
                <If	condition={this.props.loading}>
                    <div className="col-12 loader-min-height d-flex justify-content-center">
                        <div className="align-self-center">
                            <div className="text-center">
                                {/* <Loader/> */}
                            </div>
                        </div>
                    </div>
                </If>
                <If	condition={this.props.data}>
                    <div className="trigger__setting_title">
                        <h4 className="trigger__action_title">What should trigger this rule?</h4>
                    </div>

                    <div className="trigger__item">
                        <InputLabel className="trigger__action_title">
                            Which event should trigger this automation?
                        </InputLabel>
                        <FormControl className="customFormControl">
                            <CustomMenuPype
                                popup={{
                                    wrapperClass: Styles.popupWrapper,
                                    placement: "left",
                                    list: allTriggerItem,
                                    renderList:(item, index, clickCallback) =>{
                                        return (
                                            <div className={`${Styles.itemWrp} ${this.state.selected_trigger_id === item.key_index ? Styles.itemSelected : ''}`} key={index} onClick={clickCallback}>
                                                <span className={Styles.itemTitle}>{item.name}</span>
                                                <small className={Styles.itemDescription}>{item.description}</small>
                                            </div>
                                        );
                                    },
                                    onClick: (item) => this.changeValue(item.key_index,item),
                                    height: "auto",
                                    width: "__parent__",
                                    listStyle: {
                                        justifyContent: "left",
                                    },
                                }}
                                label={{
                                    renderCustom: this.renderTriggerItemLabel(),
                                }}
                            />
                        </FormControl>
                        
                        <div className="campaign__filter">
                            {/* <CampaignFilter /> */}
                            {selected_trigger_id == TRIGGER_TYPES.ADDED_TAG &&
                                <div className="campaign__filter_row" style={{padding : '24px 0px 8px'}}>
                                    <If condition={this.state.showSimpleFilter}>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Define Filters
                                            </InputLabel>
                                            <FormControl className="customFormControl">
                                                <DropdownHelper
                                                    datalist={this.state.defaultTagEventOptions}
                                                    noneFieldRequired={true}
                                                    noneFieldTitle="Select filter"
                                                    noneFieldDisabled={true}
                                                    className="trigger__select"
                                                    mapping={{ title: "label",value : "value" }}
                                                    menuItemClassName="dropdownhelper-menuitem-class"
                                                    updateSelectedData={this.actionToChangeAddedTagOption}
                                                    selectedValue={this.state.added_tag_option}
                                                    inputProps={ { 'aria-label': 'Without label' } }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Select a tag
                                            </InputLabel>

                                            <Autocomplete
                                                disableClearable
                                                className="trigger__autoComplete"
                                                options={this.state.defaultTagOptions}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e,value) => this.actionAddToTag(value)}
                                                value={this.state.selected_tag_to_take_action}
                                                renderInput={(params) => <TextField {...params} label="Select Tag" variant="outlined" />}
                                            />
                                        </div>
                                        <Button className={`action__delete topRight`}  onClick={() => this.removeSimpleFilter()}>{ iconList.redDeleteIcon }Delete</Button>
                                    </If>
                                    <If condition={!this.state.showSimpleFilter}>
                                        <div className='align-items-center d-flex justify-content-between w-100'>
                                            <Button onClick={() => this.addSimpleFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                            <p className='align-items-center d-flex mr-3 mt-2 red-text right'><Info className='mr-1'/> Filter required</p>
                                        </div>
                                    </If>
                                </div>
                            }
                            {selected_trigger_id == TRIGGER_TYPES.ADDED_CAMPAIGN &&
                                <div className="campaign__filter_row">
                                    <If condition={this.state.showSimpleFilter}>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Define Filters <span style={{color: "red"}}>*</span>
                                            </InputLabel>
                                            <FormControl className="customFormControl">
                                                <DropdownHelper
                                                    datalist={[{label:'In Campaign', value: 0}]}
                                                    className="trigger__select"
                                                    mapping={{ title: "label",value : "value" }}
                                                    menuItemClassName="dropdownhelper-menuitem-class"
                                                    updateSelectedData={this.actionToChangeAddedCampaignOption}
                                                    selectedValue={{label:'In Campaign', value: 0}}
                                                    inputProps={ { 'aria-label': 'Without label' } }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Select a Campaign
                                            </InputLabel>

                                            <Autocomplete
                                                disableClearable
                                                className="trigger__autoComplete"
                                                options={this.state.defaultCampaignOptions}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e,value) => this.actionAddToCampaign(value)}
                                                value={this.state.selected_campaign_to_take_action_in_campaign_added}
                                                renderInput={(params) => <TextField {...params} label="Campaign" variant="outlined" />}
                                            />
                                        </div>
                                        <Button className={`action__delete topRight`}  onClick={() => this.removeSimpleFilter()}>{ iconList.redDeleteIcon }Delete</Button>
                                    </If>
                                    <If condition={!this.state.showSimpleFilter}>
                                        <Button onClick={() => this.addSimpleFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                    </If>
                                </div>
                            }
                            {selected_trigger_id == TRIGGER_TYPES.CUSTOMER_BOOKED_APPOINTMENT &&
                                <div className="campaign__filter_row" style={{padding : '24px 0px 8px'}}>
                                    <If condition={this.state.showSimpleFilter}>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Define Filters <span style={{color: "red"}}>*</span>
                                            </InputLabel>
                                            <FormControl className="customFormControl">
                                                <DropdownHelper
                                                    datalist={[{label:'In Calendar', value: 0}]}
                                                    className="trigger__select"
                                                    mapping={{ title: "label",value : "value" }}
                                                    menuItemClassName="dropdownhelper-menuitem-class"
                                                    updateSelectedData={this.actionToChangeAddedCampaignOption}
                                                    selectedValue={{label:'In Calendar', value: 0}}
                                                    inputProps={ { 'aria-label': 'Without label' } }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Select Calendar
                                            </InputLabel>

                                            <Autocomplete
                                                disableClearable
                                                className="trigger__autoComplete"
                                                options={this.state.defaultCalendarOptions}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e,value) => this.actionAddToCalendar(value)}
                                                value={this.state.selected_calendar_to_take_action}
                                                renderInput={(params) => <TextField {...params} label="Calendar" variant="outlined" />}
                                            />
                                        </div>
                                        <Button className={`action__delete topRight`}  onClick={() => this.removeSimpleFilter()}>{ iconList.redDeleteIcon }Delete</Button>
                                    </If>
                                    <If condition={!this.state.showSimpleFilter}>
                                        <Button onClick={() => this.addSimpleFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                    </If>
                                </div>
                            }
                            {selected_trigger_id == TRIGGER_TYPES.FORM_SUBMISSION &&
                                <div className="campaign__filter_row" style={{padding : '24px 0px 8px'}}>
                                    <If condition={this.state.showSimpleFilter}>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Define Filters
                                            </InputLabel>
                                            <FormControl className="customFormControl">
                                                <DropdownHelper
                                                    datalist={this.state.defaultFormEventOptions}
                                                    noneFieldRequired={true}
                                                    noneFieldTitle="Select filter"
                                                    noneFieldDisabled={true}
                                                    className="trigger__select"
                                                    mapping={{ title: "label",value : "value" }}
                                                    menuItemClassName="dropdownhelper-menuitem-class"
                                                    updateSelectedData={this.actionToChangeAddedFormOption}
                                                    selectedValue={this.state.added_form_option}
                                                    inputProps={ { 'aria-label': 'Without label' } }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Select Form
                                            </InputLabel>

                                            <Autocomplete
                                                disableClearable
                                                className="trigger__autoComplete"
                                                options={this.state.defaultFormOptions}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e,value) => this.actionAddToForm(value)}
                                                value={this.state.selected_form_to_take_action}
                                                renderInput={(params) => <TextField {...params} label="Form" variant="outlined" />}
                                            />
                                        </div>
                                        <Button className={`action__delete topRight`}  onClick={() => this.removeSimpleFilter()}>{ iconList.redDeleteIcon }Delete</Button>
                                    </If>
                                    <If condition={!this.state.showSimpleFilter}>
                                        <Button onClick={() => this.addSimpleFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                    </If>
                                </div>
                            }
                            {selected_trigger_id == TRIGGER_TYPES.DEAL_STATUS_CHANGE &&
                                <div className="campaign__filter_row" style={{padding : '24px 0px 8px'}}>
                                    <If condition={this.state.showSimpleFilter}>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Select a Pipeline
                                            </InputLabel>

                                            <Autocomplete
                                                disableClearable
                                                className="trigger__autoComplete"
                                                options={this.state.defaultPipelineOptions}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e,value) => this.actionAddToTriggerDealStatus(value)}
                                                value={this.state.deal_pipeline_id}
                                                renderInput={(params) => <TextField {...params} label="Select Pipeline" variant="outlined" />}
                                            />
                                        </div>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Define Filters
                                            </InputLabel>

                                            <Autocomplete
                                                disableClearable
                                                className="trigger__autoComplete"
                                                options={this.state.defaultDealStatusEventOptions}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e,value) => this.actionToChangeDealStatusOption(value)}
                                                value={this.state.deal_status}
                                                renderInput={(params) => <TextField {...params} label="Select Deal" variant="outlined" />}
                                            />
                                        </div>
                                        <Button className={`action__delete topRight`}  onClick={() => this.removeSimpleFilter()}>{ iconList.redDeleteIcon }Delete</Button>
                                    </If>
                                    <If condition={!this.state.showSimpleFilter}>
                                        <Button onClick={() => this.addSimpleFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                    </If>
                                </div>
                            }
                            {selected_trigger_id == TRIGGER_TYPES.TASK_REMAINDER &&
                                <div className="campaign__filter_row" style={{padding : '24px 0px 8px'}}>
                                    <If condition={this.state.showSimpleFilter}>
                                        <div className="campaign__filter_col">

                                            <InputLabel className="selectLabel">
                                                Define Filters
                                            </InputLabel>
                                            <FormControl className="customFormControl">
                                                <DropdownHelper
                                                    datalist={this.state.defaultTaskRemainderEventOptions}
                                                    noneFieldRequired={true}
                                                    noneFieldTitle="Select filter"
                                                    noneFieldDisabled={true}
                                                    className="trigger__select"
                                                    mapping={{ title: "label",value : "value" }}
                                                    menuItemClassName="dropdownhelper-menuitem-class"
                                                    updateSelectedData={this.actionToChangeTaskRemainderOption}
                                                    selectedValue={this.state.task_remainder_option}
                                                    inputProps={ { 'aria-label': 'Without label' } }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="campaign__filter_col">

                                            <InputLabel className="selectLabel">
                                                Number of Day
                                            </InputLabel>

                                            <FormControl className="customFormControl">
                                                <TextField name="task_remainder_day" variant="outlined" value={this.state.task_remainder_day} onChange={this.onChangeHandle}  type="number" className="subjectField input_field_with_border" placeholder="Type number of day" />
                                            </FormControl>
                                        </div>
                                        <Button className={`action__delete topRight`}  onClick={() => this.removeSimpleFilter()}>{ iconList.redDeleteIcon }Delete</Button>
                                    </If>
                                    <If condition={!this.state.showSimpleFilter}>
                                        <div className='align-items-center d-flex justify-content-between w-100'>
                                            <Button onClick={() => this.addSimpleFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                            <p className='align-items-center d-flex mr-3 mt-2 red-text right'><Info className='mr-1'/> Filter required</p>
                                        </div>
                                    </If>
                                </div>
                            }
                            {selected_trigger_id == TRIGGER_TYPES.STAGE_CHANGE &&
                                <div className="campaign__filter_row" style={{padding : '24px 0px 8px'}}>
                                    <If condition={this.state.showSimpleFilter}>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Define Filters <span style={{color: "red"}}>*</span>
                                            </InputLabel>
                                            <FormControl className="customFormControl">
                                                <DropdownHelper
                                                    datalist={[{label:'In Pipeline', value: 0}]}
                                                    className="trigger__select"
                                                    mapping={{ title: "label",value : "value" }}
                                                    menuItemClassName="dropdownhelper-menuitem-class"
                                                    updateSelectedData={this.actionToChangeAddedCampaignOption}
                                                    selectedValue={{label:'In Pipeline', value: 0}}
                                                    inputProps={ { 'aria-label': 'Without label' } }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Select Pipeline
                                            </InputLabel>

                                            <Autocomplete
                                                disableClearable
                                                className="trigger__autoComplete"
                                                options={this.state.defaultPipelineOptions}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e,value) => this.actionAddToTriggerPipeline(value)}
                                                value={this.state.selected_pipeline_to_take_action}
                                                renderInput={(params) => <TextField {...params} label="Pipeline" variant="outlined" />}
                                            />
                                        </div>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Select Stage
                                            </InputLabel>

                                            <Autocomplete
                                                disableClearable
                                                className="trigger__autoComplete"
                                                options={this.state.defaultStageOptions}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e,value) => this.actionAddToStage(value)}
                                                value={this.state.selected_stage_to_take_action}
                                                renderInput={(params) => <TextField {...params} label="Stage" variant="outlined" />}
                                            />
                                        </div>
                                        <Button className={`action__delete topRight`}  onClick={() => this.removeSimpleFilter()}>{ iconList.redDeleteIcon }Delete</Button>
                                    </If>
                                    <If condition={!this.state.showSimpleFilter}>
                                        <div className='align-items-center d-flex justify-content-between w-100'>
                                            <Button onClick={() => this.addSimpleFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                            <p className='align-items-center d-flex mr-3 mt-2 red-text right'><Info className='mr-1'/> Filter required</p>
                                        </div>
                                    </If>
                                </div>
                            }
                            {(selected_trigger_id == TRIGGER_TYPES.ANNIVERSARY_REMAINDER || selected_trigger_id == TRIGGER_TYPES.APPOINTMENT || selected_trigger_id == TRIGGER_TYPES.BIRTHDAY_REMAINDER || selected_trigger_id == TRIGGER_TYPES.STALE_OPPORTUNITY
                                ) &&
                                <div className={"trigger__other_columns"}>
                                    <If condition={this.state.showFiltering}>
                                        <InputLabel className="selectLabel">
                                            Define Filters <span style={{color: "red"}}>*</span>
                                        </InputLabel>
                                    </If>
                                    
                                    {newFilterRowData}

                                    {(this.state.eachTriggerRow.length < this.state.selected_trigger_item_value_length) &&
                                        <>
                                        {
                                            newFilterRowData.length === 0 && 
                                            <p className='align-items-center d-flex mr-3 mt-3 red-text right'><Info className='mr-1'/> Filter required</p>
                                        }
                                        <Button onClick={() => this.addNewFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                        </>
                                    }
                                </div>
                            }

                                {(selected_trigger_id == TRIGGER_TYPES.CREATE_ACTIVITY) &&
                                <div className={"trigger__other_columns"}>
                                    <If condition={this.state.showFiltering}>
                                        <InputLabel className="selectLabel">
                                            Define Filters <span style={{color: "red"}}>*</span>
                                        </InputLabel>
                                    </If>
                                    
                                    {newFilterRowData}

                                    {(this.state.eachTriggerRow.length < this.state.selected_trigger_item_value_length) &&
                                        <>
                                        <Button onClick={() => this.addNewFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                        </>
                                    }
                                </div>
                            }
                            {(selected_trigger_id == TRIGGER_TYPES.CONTACT_REPLIED) &&
                                <div className={"trigger__other_columns"}>
                                    <If condition={this.state.showFiltering}>
                                        <InputLabel className="selectLabel">
                                            Define Filters <span style={{color: "red"}}>*</span>
                                        </InputLabel>
                                    </If>
                                    
                                    {newFilterRowData}

                                    {(this.state.eachTriggerRow.length < this.state.selected_trigger_item_value_length) &&
                                        <Button onClick={() => this.addNewFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                    }
                                </div>
                            }
                            {
                                (selected_trigger_id === TRIGGER_TYPES.INCOMING_CALL || selected_trigger_id === TRIGGER_TYPES.OUTGOING_CALL)  && 
                                    <IncomingCallFilter 
                                        defaultVirtualNumbers={this.state.defaultVirtualNumbers}
                                        showSimpleFilter={this.state.showSimpleFilter}
                                        selectedVirtualNumberToTakeAction={this.state.selected_virtual_number_to_take_action}
                                        addNewFilter={this.addNewFilter}
                                        actionAddToTriggerIncomingCall={this.actionAddToTriggerIncomingCall}
                                        addSimpleFilter={() => this.addSimpleFilter()}
                                        removeSimpleFilter={() => this.removeSimpleFilter()}
                                    />
                            }

                            {selected_trigger_id == TRIGGER_TYPES.APPOINTMENT_STATUS_CHANGE &&
                                <div className="campaign__filter_row" style={{padding : '24px 0px 8px'}}>
                                    <If condition={this.state.showSimpleFilter}>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Define Filters <span style={{color: "red"}}>*</span>
                                            </InputLabel>
                                            <FormControl className="customFormControl">
                                                <DropdownHelper
                                                    datalist={[{label:'Marked As', value: 0}]}
                                                    className="trigger__select"
                                                    mapping={{ title: "label",value : "value" }}
                                                    menuItemClassName="dropdownhelper-menuitem-class"
                                                    updateSelectedData={this.actionToChangeAddedCampaignOption}
                                                    selectedValue={{label:'Marked As', value: 0}}
                                                    inputProps={ { 'aria-label': 'Without label' } }
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Select Event
                                            </InputLabel>

                                            <Autocomplete
                                                disableClearable
                                                className="trigger__autoComplete"
                                                options={[
                                                    {id : 0,label : 'Done'}
                                                ]}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e,value) => this.actionAppointmentMarkAsDoneChange(value)}
                                                value={this.state.selected_appointment_mark_as_to_take_action}
                                                renderInput={(params) => <TextField {...params} label="Event" variant="outlined" />}
                                            />
                                        </div>
                                        <Button className={`action__delete topRight`}  onClick={() => this.removeSimpleFilter()}>{ iconList.redDeleteIcon }Delete</Button>
                                    </If>
                                    <If condition={!this.state.showSimpleFilter}>
                                        <div className='align-items-center d-flex justify-content-between w-100'>
                                            <Button onClick={() => this.addSimpleFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                            <p className='align-items-center d-flex mr-3 mt-2 red-text right'><Info className='mr-1'/> Filter required</p>
                                        </div>
                                    </If>
                                </div>
                            }

                            {selected_trigger_id == TRIGGER_TYPES.MISSED_CALL &&
                                <div className="campaign__filter_row" style={{padding : '24px 0px 8px'}}>
                                    <If condition={this.state.showSimpleFilter}>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Define Filters <span style={{color: "red"}}>*</span>
                                            </InputLabel>
                                            <FormControl className="customFormControl">
                                                <Autocomplete
                                                    disableClearable
                                                    className="trigger__autoComplete"
                                                    options={MISSED_CALL_FILTER_OPTIONS}
                                                    getOptionLabel={(option) => option.label}
                                                    onChange={(e,value) => this.actionChangeMissedCall(value)}
                                                    value={this.state.selected_missed_call_filter}
                                                    renderInput={(params) => <TextField {...params} label="Filter" variant="outlined" />}
                                                />
                                            </FormControl>
                                        </div>
                                        <div className="campaign__filter_col">
                                            <InputLabel className="selectLabel">
                                                Apply To:
                                            </InputLabel>

                                            <Autocomplete
                                                disableClearable
                                                className="trigger__autoComplete"
                                                options={[...VIRTUAL_NUMBER_ALL,...newVirtualNumber]}
                                                getOptionLabel={(option) => option.label}
                                                onChange={(e,value) => this.actionAddToTriggerIncomingCall(value)}
                                                value={this.state.selected_virtual_number_to_take_action}
                                                renderInput={(params) => <TextField {...params} label="Filter" variant="outlined" />}
                                            />
                                        </div>
                                        <Button className={`action__delete topRight`}  onClick={() => this.removeSimpleFilter()}>{ iconList.redDeleteIcon }Delete</Button>
                                    </If>
                                    <If condition={!this.state.showSimpleFilter}>
                                        <div className='align-items-center d-flex justify-content-between w-100'>
                                            <Button onClick={() => this.addSimpleFilter()} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
                                            <p className='align-items-center d-flex mr-3 mt-2 red-text right'><Info className='mr-1'/> Filter required</p>
                                        </div>
                                    </If>
                                </div>
                            }
                        </div>

                        {(this.state.triggerValue != null && (this.state.triggerValue === TRIGGER_TYPES.TWILIO_MESSAGE_FILTER || this.state.triggerValue === TRIGGER_TYPES.TWILIO_DAILY_MESSAGE_CAP)) &&                        
                        <div className='twilio_error_code_hints_wrp'>
                          <span className='techw_header'>{this.state.triggerValue === TRIGGER_TYPES.TWILIO_MESSAGE_FILTER ? 'What is Message filtered?' : 'What is the Daily Message Cap Reached'}</span>
                          <div className='techw_body_wrp'>
                            {this.state.triggerValue === TRIGGER_TYPES.TWILIO_MESSAGE_FILTER && 
                              <>
                                <div
                                  className='techw_bw_each_wrp'
                                >
                                  <span className='techw_bwew_dot'></span>
                                  <div className='techw_bwew_message'>
                                  Your message content was flagged as going against carrier guidelines. 
                                  Your message was filtered (blocked) by Twilio or by the carrier. 
                                  This may be done by Twilio for violating Twilio’s <a href="https://www.twilio.com/legal/messaging-policy" target={"_blank"} rel="noreferrer" alt="">Messaging Policy</a> or <a href="https://www.twilio.com/legal/aup" target="_blank" rel="noreferrer" alt="">Acceptable Use Policy</a>, or by a wireless carrier for violating carrier rules or regulations.
                                  </div>
                                </div>

                                <div
                                  className='techw_bw_each_wrp'
                                >
                                  <span className='techw_bwew_dot'></span>
                                  <span className='techw_bwew_message'>
                                  Examples of messaging that would be blocked by Twilio are spam, phishing, and fraud. Twilio’s filtering system is in place to protect mobile subscribers from spam or other forms of malicious or unwanted messages.
                                  </span>
                                </div>

                                <div
                                  className='techw_bw_each_wrp'
                                >
                                  <span className='techw_bwew_dot'></span>
                                  <span className='techw_bwew_message'>
                                  Wireless carriers have filtering systems in place as well. These carrier filters are used to block abusive traffic, as well as to enforce rules or regulations about what types of messaging are allowed to that country or mobile network.
                                  </span>
                                </div>
                              </>
                            }
                            {this.state.triggerValue === TRIGGER_TYPES.TWILIO_DAILY_MESSAGE_CAP && 
                              <>
                              <div
                                className='techw_bw_each_wrp'
                              >
                                <span className='techw_bwew_dot'></span>
                                <span className='techw_bwew_message'>
                                Your messages to this particular carrier have exceeded the maximum allowable messages for the day. You have sent the maximum allowable daily messages for your Brand to the carrier.
                                </span>
                              </div>

                              <div
                                className='techw_bw_each_wrp'
                              >
                                <span className='techw_bwew_dot'></span>
                                <span className='techw_bwew_message'>
                                A possible case is you have sent the maximum amount of messages for the day.
                                </span>
                              </div>

                              <div
                                className='techw_bw_each_wrp'
                              >
                                <span className='techw_bwew_dot'></span>
                                <span className='techw_bwew_message'>
                                The solution is you must wait till the next calendar day to resume message sending. The day resets at 00:00 Pacific Time (US). Pacific time is affected by Daylight Savings Time and Standard Time switches.
                                </span>
                              </div>
                            </>
                            }
                          </div>
                        </div>
                        }
                    </div>
                </If>
            </>
        );
    }
}
const TriggerItem = connect(mapStateToProps, mapDispatchToProps)(Trigger);
export default TriggerItem;