import React from "react";
import { iconList } from "../../globals/IconList";
import { Button, FormControl, InputLabel } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { DropdownHelper } from "../../globals/DropdownHelper";
import "../trigger-settings.css";
import { Info } from "@material-ui/icons";

const IncomingCallFilter = (props) => {

  let oldVirtualNumber = props.defaultVirtualNumbers;
  let newVirtualNumber = [{id: 0, label: 'User default number', value: '{{user_default_number}}'}, ...oldVirtualNumber];

  return (
    <div className={`campaign__filter_row`} style={{padding : '24px 0px 8px'}}>
      {props.showSimpleFilter ? (
        <>
          <div className="campaign__filter_col">
            <InputLabel className="selectLabel">
              Define Filters <span style={{ color: "red" }}>*</span>
            </InputLabel>
            <FormControl className="customFormControl">
              <DropdownHelper
                datalist={[{ label: "In Virtual Number", value: 0 }]}
                className="trigger__select"
                mapping={{ title: "label", value: "value" }}
                menuItemClassName="dropdownhelper-menuitem-class"
                updateSelectedData={props.actionAddToTriggerIncomingCall}
                selectedValue={{ label: "In Virtual Number", value: 0 }}
                inputProps={{ "aria-label": "Without label" }}
              />
            </FormControl>
          </div>

          <div className="campaign__filter_col">
            <InputLabel className="selectLabel">
              Select a Virtual Number
            </InputLabel>

            <Autocomplete
              disableClearable
              className="trigger__autoComplete"
              options={newVirtualNumber}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) =>
                props.actionAddToTriggerIncomingCall(value)
              }
              value={props.selectedVirtualNumberToTakeAction}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Virtual Numbers"
                  variant="outlined"
                />
              )}
            />
          </div>
          <Button
            className={`action__delete topRight`}
            onClick={props.removeSimpleFilter}
          >
            {iconList.redDeleteIcon}Delete
          </Button>
        </>
      ) : (
        <div className='align-items-center d-flex justify-content-between w-100'>
            <Button onClick={props.addSimpleFilter} className="trigger__item_btn filter_btn">{ iconList.bluePlusIcon }Add Filter</Button>
            <p className='align-items-center d-flex mr-3 mt-2 red-text right'><Info className='mr-1'/> Filter required</p>
        </div>
      )}
    </div>
  );
};

export default IncomingCallFilter;
