import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import React, { useState } from 'react'
import './GlobalDropdown.css';

export default function GlobalDropdown(props) {
  const [ anchorActionMenu, setAnchorActionMenu ] = useState(null);
  
  let buttonChildren = [];

  const renderActionMenu = () => {

    let items = [];

    if(Array.isArray(props.children)){
      props.children.forEach((elem,index) => {

        const clickHandler = (e,element) => {
          if(element.props.onClickHide !== undefined && element.props.onClickHide) {
            setAnchorActionMenu(null);
          }
          element.props.onClick(e)
        }

        if (elem.type != undefined && elem.type.name === GlobalDropDownItem.name) {
          items.push(
            <MenuItem
              onClick={(e) => clickHandler(e,elem)} 
              key={ ("action-menu-" + index) }
              className={elem.props.className}>
              <ListItemIcon >
                <span>
                  { elem.props.icon }
                </span>
              </ListItemIcon>
              <ListItemText inset>
                { elem.props.title }
              </ListItemText>
            </MenuItem>
          );
        } else if(Array.isArray(elem)) {
          elem.forEach((item,index) => {
            items.push(
              <MenuItem
                onClick={(e) => clickHandler(e,item)} 
                key={ ("action-menu-" + index) }
                className={item.props.className}>
                <ListItemIcon >
                  <span>
                    { item.props.icon }
                  </span>
                </ListItemIcon>
                <ListItemText inset>
                  { item.props.title }
                </ListItemText>
              </MenuItem>
            );
          })
        } else {
          buttonChildren.push(elem);
        }
      });

      return items;

    } else {
        buttonChildren = props.children;
    }
    return null;
  }

  return (
    <>
      {/* <div> */}
        <div onClick={ (e) => setAnchorActionMenu(e.currentTarget) }>
          {buttonChildren}
        </div>


        <Menu
          className="trigger__folderDropdown global__dropdown"
          id="action-menu"
          anchorEl={ anchorActionMenu }
          keepMounted
          open={ Boolean(anchorActionMenu) }
          onClose={ () => setAnchorActionMenu(null) }
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
        >
          { renderActionMenu() }
        </Menu>

      {/* </div> */}
    </>
  )
}


export const GlobalDropDownButton = (props) => {
  return props.children;
}

export const GlobalDropDownItem = (props) => {
  return props.children;
}
