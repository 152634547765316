import { connect } from 'react-redux';
import { cloneAgencyTemplates, fetchTriggers } from '../../actions/triggerAction';
import GlobalDropdown, { GlobalDropDownButton, GlobalDropDownItem } from "../globals/Dropdown/GlobalDropdown";
import { iconList } from "../globals/IconList";

const EachTemplateApp = (props) => {
    const handleCloneAgencyTemplates = () => {
        props.cloneAgencyTemplates({
            agency_folder_id : props.folder.id
        },(res) => {
            if(res.data.success) {
              window.showNotification('SUCCESS', res.data.message);
              props.fetchTriggers();
            }else {
              window.showNotification('ERROR', res.data.message);
            }
        });
    }

    return (
        <>
            <div className="templateItem">
                <img src="/assets/images/folder.svg" alt="" />
                <h3 className="templateItem__title">{props.folder.name}</h3>
                <p className="templateItem__counts">
                    <span>{props.folder.trigger_folder_public_items_count}</span>Rules
                </p>
                <GlobalDropdown>
                    <GlobalDropDownButton>
                        <span className='dropdownTrigger' dataTarget='campaign_list_dropdown' >
                            <svg width="8" height="30" viewBox="0 0 8 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.56293 18.4612C5.50128 18.4612 7.07263 16.9114 7.07263 14.9996C7.07263 13.0879 5.50128 11.5381 3.56293 11.5381C1.62457 11.5381 0.0532227 13.0879 0.0532227 14.9996C0.0532227 16.9114 1.62457 18.4612 3.56293 18.4612Z" fill="#133159" />
                            <path d="M3.56293 30.0002C5.50128 30.0002 7.07263 28.4504 7.07263 26.5387C7.07263 24.6269 5.50128 23.0771 3.56293 23.0771C1.62457 23.0771 0.0532227 24.6269 0.0532227 26.5387C0.0532227 28.4504 1.62457 30.0002 3.56293 30.0002Z" fill="#133159" />
                            <path d="M3.56293 6.92308C5.50128 6.92308 7.07263 5.37329 7.07263 3.46154C7.07263 1.54978 5.50128 0 3.56293 0C1.62457 0 0.0532227 1.54978 0.0532227 3.46154C0.0532227 5.37329 1.62457 6.92308 3.56293 6.92308Z" fill="#133159" />
                        </svg>
                        </span>
                    </GlobalDropDownButton>
                    <GlobalDropDownItem onClick={() => handleCloneAgencyTemplates()} onClickHide={true} title='Clone Folder' icon={iconList.duplicateIcon}/>
                </GlobalDropdown>
            </div>
        </>
    );
};

const mapDispatchToProps = dispatch => {
    return {
        cloneAgencyTemplates: (params,callBack) => dispatch(cloneAgencyTemplates(params,callBack)),
        fetchTriggers: (params) => dispatch(fetchTriggers(params)),
    };
}

const mapStateToProps = state => ({});

const EachTemplate = connect(mapStateToProps, mapDispatchToProps)(EachTemplateApp);

export default EachTemplate; 