import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as ACTION from '../constants/triggerActionTypes'
import * as TRIGGER_API from "../api/triggerApi";


function* triggerWatcher() {
    yield takeEvery(ACTION.TRIGGER_SETTING_GET, fetchTriggerRule)
    yield takeEvery(ACTION.TRIGGER_SETTING_SAVE, saveTriggerSetting)
}

function* fetchTriggerRule(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.getTriggerSetting, payload.payload);
        let responseData = response.data.data;
        const storeData = {
            previousActionValue: responseData.triggerRule.rule_trigger_action != null ? responseData.triggerRule.rule_trigger_action.user_trigger_actions:[],
            triggerRule : responseData.triggerRule,
            allTriggerItem : responseData.allTriggerItem,
            allActionItem: responseData.allActionItem,
            allCampaign: responseData.allCampaign,
            allCalendar: responseData.allCalendar,
            allTag: responseData.allTag,
            allPipeline: responseData.allPipeline,
            allStage: responseData.allStage,
            allCollaborator: responseData.allCollaborator,
            allVirtualNumber: responseData.allVirtualNumber,
            allEmail: responseData.allEmail,
            agency: responseData.agency,
            profile: responseData.profile,
            personalize: responseData.personalize,
            allList: responseData.allList,
            allForm: responseData.allForm,
            email_templates: responseData.email_templates,
            allIntegrations: responseData.allIntegrations,
            loading: false,
            required_error : false,
            data : true,
            allSources: responseData.allSources
        };
        yield put({type: ACTION.STORE_TRIGGER_SETTING, payload: storeData});
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}

function* saveTriggerSetting(action) {
    const { payload } = action;
    try {
        const response = yield call(TRIGGER_API.saveTriggerSetting, payload.payload);
        if(payload.callBack !== undefined) {
            payload.callBack(response);
        }
    } catch (error) {
        // yield put({type: ACTION.FAILED_FETCH_CAMPAIGNS});
        // Utils.handleException(error);
    }
}


export default function* triggerSettingMiddleware() {
    yield all([
        triggerWatcher()
    ])
}