import { useState } from "react";
import ModalNewFolder from "./ModalNewFolder";
import ModalAddNewRule from "./ModalAddNewRule";

const TriggerHead = () => {

	const [isOpenNewFolderModal,setIsOpenNewFolderModal] = useState(false);
	const [isOpenNewRuleModal,setIsOpenNewRuleModal] = useState(false);

    return (
		<> 
        <div class="trigger__topbar flex items-center content-between">
			<h2 class="trigger__title">Trigger</h2>
			<div class="trigger__btns">
				<button class="trigger__btn add__newFolder trigger__blueBtn accent--bg--text--color" onClick={() => setIsOpenNewFolderModal(true)}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M19.375 5.9375C19.375 5.35734 19.1445 4.80094 18.7343 4.39071C18.3241 3.98047 17.7677 3.75 17.1875 3.75H8.59805C8.41308 3.7506 8.23212 3.69621 8.07813 3.59375L6.99219 2.86758C6.63259 2.62712 6.20954 2.49916 5.77695 2.5H2.8125C2.23234 2.5 1.67594 2.73047 1.2657 3.14071C0.855468 3.55094 0.625 4.10734 0.625 4.6875V6.5625C0.625 6.64538 0.657924 6.72487 0.716529 6.78348C0.775134 6.84208 0.85462 6.875 0.9375 6.875H19.0625C19.1454 6.875 19.2249 6.84208 19.2835 6.78348C19.3421 6.72487 19.375 6.64538 19.375 6.5625V5.9375ZM0.625 15.3125C0.625 15.8927 0.855468 16.4491 1.2657 16.8593C1.67594 17.2695 2.23234 17.5 2.8125 17.5H17.1875C17.7677 17.5 18.3241 17.2695 18.7343 16.8593C19.1445 16.4491 19.375 15.8927 19.375 15.3125V8.4375C19.375 8.35462 19.3421 8.27514 19.2835 8.21653C19.2249 8.15793 19.1454 8.125 19.0625 8.125H0.9375C0.85462 8.125 0.775134 8.15793 0.716529 8.21653C0.657924 8.27514 0.625 8.35462 0.625 8.4375V15.3125Z"/> </svg> Add New Folder</button>
				<button class="trigger__btn add__newBasicRule trigger__blueBtn accent--bg--text--color" onClick={() => setIsOpenNewRuleModal(true)}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <circle cx="10" cy="10" r="10"/> <path d="M13.8461 10C13.8461 10.3545 13.8153 10.641 13.4609 10.641H10.641V13.4609C10.641 13.8148 10.3544 13.8462 9.99996 13.8462C9.64548 13.8462 9.35894 13.8148 9.35894 13.4609V10.641H6.53906C6.18522 10.641 6.15381 10.3545 6.15381 10C6.15381 9.64554 6.18522 9.359 6.53906 9.359H9.35894V6.53913C9.35894 6.18464 9.64548 6.15387 9.99996 6.15387C10.3544 6.15387 10.641 6.18464 10.641 6.53913V9.359H13.4609C13.8153 9.359 13.8461 9.64554 13.8461 10Z" className={"accent--fill--color"}/> </svg> Add New Trigger</button>
			</div>
		</div>

		{
			isOpenNewFolderModal &&
			<ModalNewFolder
				open={isOpenNewFolderModal}
				onClose={() => setIsOpenNewFolderModal(false)}/>
		}

		{
			isOpenNewRuleModal && 
			<ModalAddNewRule
				open={isOpenNewRuleModal}
				onClose={() => setIsOpenNewRuleModal(false)}/>
		}
		
		</> 
	);
}
 
export default TriggerHead;