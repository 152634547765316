import * as ACTION from "../constants/triggerActionTypes";

const initialState = {
    triggerFolders : [],
    triggerWithoutFolders : [],
    triggerAgencyTemplateFolders:[],
    isLoading : true,
};

const triggerReducer = (state = initialState, action) => {
    switch (action.type) {
        // case ACTION.FETCH_TRIGGERS:
        //     return {...state, isLoading: true}
        case ACTION.STORE_TRIGGER_LIST:
            return {...state, isLoading: false, triggerFolders : action.payload.data.allFolderTriggerItems, triggerWithoutFolders : action.payload.data.allTriggerExceptFolderItems}
        case ACTION.STORE_AGENCY_TEMPLATES:
            return {...state, triggerAgencyTemplateFolders : action.payload.data.agencyTriggerFolders}
        default:
            return state;
    }
}


export default triggerReducer;
