import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export const DropdownHelper = (props) => {

    const [datalist, setDatalist] = useState([]);


    useEffect(() => {
        if (props.datalist != undefined && props.datalist != null && props.datalist.length > 0) {
            setDatalist(props.datalist);
        }
    }, [])

    const updateSelectedData = async (event) => {
        let selectedObj = datalist.find(x => x[props.mapping.value] === event.target.value);
        await props.updateSelectedData(selectedObj === undefined ? null : selectedObj);
    }

    return (
        <React.Fragment>
            <Select
                displayEmpty
                value={props.selectedValue === null ? null : props.selectedValue[props.mapping.value]}
                onChange={updateSelectedData}
                className={(props.className != undefined && props.className != null) ? props.className : ''}
                inputProps={{ 'aria-label': (props.inputProps != undefined && props.inputProps != null) ? props.inputProps : 'Without label' }}
            >
                {props.noneFieldRequired != undefined && props.noneFieldRequired != null && props.noneFieldRequired &&
                    <MenuItem value={null} disabled={props.noneFieldDisabled === true}>
                        {(props.noneFieldTitle != undefined && props.noneFieldTitle != null) ? props.noneFieldTitle : 'None'}
                    </MenuItem>
                }
                {datalist.length > 0 &&
                    datalist.map((data, index) => (
                        <MenuItem
                            value={data[props.mapping.value]}
                            className={(props.menuItemClassName != undefined && props.menuItemClassName != null) ? props.menuItemClassName : ''}
                        >{data[props.mapping.title]}</MenuItem>

                    ))
                }
            </Select>
        </React.Fragment>
    )
}




